import {
  Button,
  Flex,
  HStack,
  InputGroup,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { FC, useRef, useState } from 'react'

export const ArchiveChipsInput: FC<{
  title: string
  values: string[]
  onChange: (values: string[]) => void
  onClose: () => void
}> = ({ values, title, onChange, onClose }) => {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setInputValue(event.target.value)
  }

  const handleSubmit = (): void => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue) {
      addChips(trimmedValue)
      onChange([...values, trimmedValue])
      setInputValue('')
    }
  }

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault()
      handleSubmit()
    } else if (
      event.key === 'Backspace' &&
      inputValue === '' &&
      values.length > 0
    ) {
      event.preventDefault()
      onChange(values.slice(0, -1))
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const pasteData = event.clipboardData.getData('Text')
    addChips(pasteData)
  }

  const addChips = (chips: string): void => {
    const newChips = chips.split(',').map((chip) => chip.trim())
    if (newChips.length > 0) {
      onChange([...new Set([...values, ...newChips])])
    }
  }

  const removeChip = (index: number): void => {
    onChange(values.toSpliced(index, 1))
  }

  const handleClearAll = (): void => {
    setInputValue('')
    onChange([])
    inputRef.current?.focus()
  }

  return (
    <InputGroup id="inputgroup" maxW="100%">
      <VStack flexGrow={1} maxW="100%" align="flex-start">
        <Flex w="100%" py={1} px={2}>
          <HStack
            maxH="4.6rem"
            flexWrap="wrap"
            overflowY="auto"
            py={1}
            px={2}
            gap={0}
            borderWidth={1}
            onClick={() => inputRef.current?.focus()}
            padding={2}
            border="solid 1px"
            borderColor="border.light"
            borderRadius="md"
            tabIndex={0}
            alignItems="start"
            w="100%"
            _focusWithin={{
              borderColor: 'primary.500',
              boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
              shadow: 'extended',
            }}
            sx={{
              '&::-webkit-scrollbar': {
                width: '0px',
              },
              '&': {
                scrollbarWidth: 'none',
              },
            }}
          >
            <Flex flexWrap="wrap" overflowY="scroll" flex="80%">
              {values.map((chip, index) => (
                <Tag
                  key={`${chip}-${index}`}
                  size="xs"
                  borderRadius="full"
                  px={2}
                  mx={1}
                  mb={2}
                  variant="subtle"
                  maxW="100%"
                  h={5}
                >
                  <TagLabel fontSize="xs" fontWeight="normal">
                    {chip}
                  </TagLabel>
                  <TagCloseButton
                    ml={1}
                    h={4}
                    onClick={() => removeChip(index)}
                  />
                </Tag>
              ))}
              <chakra.input
                ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                onBlur={handleSubmit}
                onPaste={handlePaste}
                autoFocus={true}
                placeholder={title}
                fontSize="sm"
                border="none"
                outline="none"
                h={5}
                mx={1}
              />
            </Flex>
            <Flex
              pointerEvents="auto"
              w="auto"
              justify="flex-end"
              flex="20%"
              align="end"
            >
              {values.length > 0 && (
                <Button
                  variant="ghost"
                  _hover={{ backgroundColor: 'none' }}
                  _focus={{ backgroundColor: 'none' }}
                  aria-label={t`Clear all`}
                  onClick={handleClearAll}
                >
                  <Trans>Clear all </Trans>
                </Button>
              )}
            </Flex>
          </HStack>
        </Flex>
        <Flex
          boxShadow="0px -4px 8px -6px rgba(0, 0, 0, 0.20)"
          w="100%"
          justify="flex-end"
          pt={2}
          pr={2}
        >
          <Button variant="ghost" onClick={onClose}>
            <Trans>OK</Trans>
          </Button>
        </Flex>
      </VStack>
    </InputGroup>
  )
}
