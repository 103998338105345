import { Divider, HStack, IconButton } from '@chakra-ui/react'
import { FC, ReactElement, useState } from 'react'

type ButtonSide = 'left' | 'right'

type ButtonGroupProps = {
  leftButtonProps: {
    icon: ReactElement
    'aria-label': string
    onClick: () => void
  }
  rightButtonProps: {
    icon: ReactElement
    'aria-label': string
    onClick: () => void
  }
  defaultActive?: ButtonSide[]
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
  leftButtonProps,
  rightButtonProps,
  defaultActive = ['left'],
}) => {
  const [activeButtons, setActiveButtons] =
    useState<ButtonSide[]>(defaultActive)

  const handleClick = (side: ButtonSide, onClick: () => void) => {
    setActiveButtons((prev) =>
      prev.includes(side)
        ? prev.filter((btn) => btn !== side)
        : [...prev, side],
    )
    onClick()
  }

  return (
    <HStack spacing={0}>
      <IconButton
        minH="32px"
        icon={leftButtonProps.icon}
        aria-label={leftButtonProps['aria-label']}
        onClick={() => handleClick('left', leftButtonProps.onClick)}
        borderRightRadius={0}
        colorScheme={
          activeButtons.includes('left') ? 'segments.primary' : 'gray'
        }
        boxShadow="extended"
        borderBottom="2px solid"
        borderBottomColor={
          activeButtons.includes('left') ? 'primary.700' : 'gray.300'
        }
      />
      <Divider
        orientation="vertical"
        borderColor={activeButtons.length === 2 ? 'primary.500' : 'gray.300'}
        opacity={0.7}
      />
      <IconButton
        minH="32px"
        icon={rightButtonProps.icon}
        aria-label={rightButtonProps['aria-label']}
        onClick={() => handleClick('right', rightButtonProps.onClick)}
        borderLeftRadius={0}
        colorScheme={
          activeButtons.includes('right') ? 'segments.primary' : 'gray'
        }
        boxShadow="extended"
        borderBottom="2px solid"
        borderBottomColor={
          activeButtons.includes('right') ? 'primary.700' : 'gray.300'
        }
      />
    </HStack>
  )
}
