import { Box, Button, Flex, Heading, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useQueryClient } from '@tanstack/react-query'
import { FC } from 'react'
import { useNavigate } from 'react-router'

import { QuestionnaireEditor } from './components/QuestionnaireEditor'
import { routes } from './routes'
import { QaQuestionnaire } from './types'
import { useEditorStore } from './useQuestionnaireEditorStore'

export const QAQuestionnaireCreatePage: FC = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const handleCancel = () => {
    navigate(routes.root)
  }

  // TODO: Replace with API mutations
  const queryClient = useQueryClient()
  const handleSave = () => {
    const questionnaire = useEditorStore.getState().questionnaire

    try {
      queryClient.setQueryData(
        ['questionnaireList'],
        (old: QaQuestionnaire[]) => {
          return [...(old || []), questionnaire]
        },
      )
      queryClient.setQueryData(
        ['questionnaire', questionnaire.uid],
        questionnaire,
      )

      toast({
        title: t`Questionnaire saved`,
        status: 'success',
        duration: 3000,
      })
      navigate(routes.root)
    } catch (error) {
      toast({
        title: t`Error saving questionnaire`,
        description: error instanceof Error ? error.message : t`Unknown error`,
        status: 'error',
        duration: 5000,
      })
    }
  }

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading as="h1" size="lg">
          {t`Create QA Questionnaire`}
        </Heading>
        <Flex gap={2}>
          <Button variant="outline" onClick={handleCancel}>
            {t`Cancel`}
          </Button>
          <Button colorScheme="teal" onClick={handleSave}>
            {t`Save as Draft`}
          </Button>
        </Flex>
      </Flex>
      <QuestionnaireEditor />
    </Box>
  )
}
