import { CSSProperties } from 'react'

export const styles: Record<string, CSSProperties> = {
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px',
    cursor: 'pointer',
    width: '100%',
  },
  selectedOption: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px',
    cursor: 'pointer',
    width: '100%',
    backgroundColor: 'var(--chakra-colors-gray-100)',
    fontWeight: '500',
  },
}
