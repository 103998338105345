import { useFeatureFlags } from '@capturi/feature-flags'
import { FC } from 'react'
import { Route, Routes } from 'react-router'

import { QAQuestionnaireCreatePage } from './QAQuestionnaireCreatePage'
import { QAQuestionnaireEditPage } from './QAQuestionnaireEditPage'
import { QAQuestionnaireListPage } from './QAQuestionnaireListPage'

export { routes } from './routes'

const KeyTopicsRouter: FC = () => {
  const { enableQAQuestionnaire } = useFeatureFlags()

  if (!enableQAQuestionnaire) {
    return null
  }

  return (
    <Routes>
      <Route path="/*" element={<QAQuestionnaireListPage />} />
      <Route path="create" element={<QAQuestionnaireCreatePage />} />
      <Route path="edit/:uid" element={<QAQuestionnaireEditPage />} />
    </Routes>
  )
}

export default KeyTopicsRouter
