import { Box, Divider, Spinner } from '@chakra-ui/react'
import { useFilterData } from 'pages/Archive/api/useArchivedConversations'
import { FC, useCallback, useMemo, useState } from 'react'
import { FilterSelect } from '../../FilterSelect/components/FilterSelect'
import { Footer } from '../../FilterSelect/components/FilterSelectFooter'

type Props = {
  values?: string[]
  onSubmit: (values: string[]) => void
  onClose: () => void
}

export const UserNamesSelect: FC<Props> = ({
  values = [],
  onSubmit,
  onClose,
}) => {
  const { data } = useFilterData('userNames')
  const [searchQuery, setSearchQuery] = useState('')

  const filteredOptions = useMemo(() => {
    if (!data?.values) return []
    if (!searchQuery) return data.values

    return data.values.filter((userName) =>
      userName.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [data?.values, searchQuery])

  const handleSelect = (userName: string) => {
    if (values.includes(userName)) {
      onSubmit(values.filter((s) => s !== userName))
    } else {
      onSubmit([...values, userName])
    }
  }

  const handleSelectAll = useCallback(() => {
    const newSelections =
      filteredOptions.filter((item) => !values.includes(item)) ?? []
    onSubmit([...new Set([...values, ...newSelections])])
    setSearchQuery('')
  }, [values, filteredOptions, onSubmit])

  const handleReset = useCallback(() => {
    onSubmit([])
  }, [onSubmit])

  const { selectedItems, unselectedItems } = useMemo(
    () =>
      filteredOptions.reduce<{
        selectedItems: string[]
        unselectedItems: string[]
      }>(
        (acc, userName) => {
          if (values.includes(userName)) {
            acc.selectedItems.push(userName)
          } else {
            acc.unselectedItems.push(userName)
          }
          return acc
        },
        { selectedItems: [], unselectedItems: [] },
      ),
    [filteredOptions, values],
  )

  if (!data) {
    return <Spinner />
  }

  return (
    <Box maxH="400px" display="flex" flexDirection="column">
      <Box px={2}>
        <FilterSelect.Search
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      <Divider />
      <Box flex={1} maxH="350px" overflowY="auto">
        <FilterSelect.FilterSelectOptions
          selected={values.length}
          total={filteredOptions.length}
          selectedItems={selectedItems.map((userName) => (
            <FilterSelect.OptionItem
              key={userName}
              isSelected={true}
              onClick={() => handleSelect(userName)}
            >
              {userName}
            </FilterSelect.OptionItem>
          ))}
          unselectedItems={unselectedItems.map((userName) => (
            <FilterSelect.OptionItem
              key={userName}
              isSelected={false}
              onClick={() => handleSelect(userName)}
            >
              {userName}
            </FilterSelect.OptionItem>
          ))}
        />
      </Box>
      <Box flexShrink={0}>
        <Footer
          selectedCount={values.length}
          onSelectAll={handleSelectAll}
          onReset={handleReset}
          onApply={onClose}
        />
      </Box>
    </Box>
  )
}
