import { produce } from 'immer'
import { v4 as uuidv4 } from 'uuid'
import { create } from 'zustand'
import {
  ConditionTarget,
  ContinueToNextTarget,
  FreeTextQuestion,
  Guid,
  QaQuestionnaire,
  Question,
  Section,
  YesNoQuestion,
} from './types'

export interface ValidationErrorsRecord {
  missingTitle: boolean
  invalidSections: Set<Guid>
  invalidQuestions: Set<Guid>
}

const calculateValidationErrors = (
  questionnaire: QaQuestionnaire,
): ValidationErrorsRecord => {
  const errors: ValidationErrorsRecord = {
    missingTitle: false,
    invalidSections: new Set(),
    invalidQuestions: new Set(),
  }
  if (!questionnaire.title.trim()) {
    errors.missingTitle = true
  }
  questionnaire.sections.forEach((section) => {
    if (!section.title.trim()) {
      errors.invalidSections.add(section.uid)
    }
    if (section.questions.length === 0) {
      errors.invalidSections.add(section.uid)
    }
    section.questions.forEach((question) => {
      if (!question.question.trim()) {
        errors.invalidQuestions.add(question.uid)
      }
    })
  })
  return errors
}

/**
 * Creating a small helper to generate empty default structures.
 */

const createDefaultQuestionnaire = (): QaQuestionnaire => {
  const now = new Date()
  const creatorUid = crypto.randomUUID()
  return {
    uid: creatorUid,
    organizationUid: crypto.randomUUID(),
    title: '',
    created: now,
    createdByUserUid: creatorUid,
    updated: now,
    updatedByUserUid: creatorUid,
    published: false,
    publishedAt: null,
    deleted: false,
    deletedByUserUid: null,
    deletedAt: null,
    sections: [],
  }
}

const createDefaultSection = (): Section => {
  return {
    uid: uuidv4(),
    title: '',
    questions: [],
  }
}

const createDefaultFreeTextQuestion = (): FreeTextQuestion => ({
  type: 'freetext',
  uid: uuidv4(),
  question: '',
  description: null,
  next: { type: 'nextQuestion' } as ContinueToNextTarget,
})

const createDefaultYesNoQuestion = (): YesNoQuestion => ({
  type: 'yesNo',
  uid: uuidv4(),
  question: '',
  description: null,
  next: { type: 'nextQuestion' } as ContinueToNextTarget,
  answers: [
    {
      answer: 'yes',
      mnemonic: 'y',
      next: { type: 'nextQuestion' } as ContinueToNextTarget,
    },
    {
      answer: 'no',
      mnemonic: 'n',
      next: { type: 'nextQuestion' } as ContinueToNextTarget,
    },
  ],
})

export interface QuestionnaireEditorState {
  questionnaire: QaQuestionnaire
  validationErrors: ValidationErrorsRecord
}

export interface SelectedQuestion {
  sectionUid: Guid
  questionUid: Guid
}

export interface QuestionnaireEditorActions {
  resetQuestionnaire: () => void
  loadQuestionnaire: (q: QaQuestionnaire) => void

  setTitle: (title: string) => void

  selectedQuestion: SelectedQuestion | null
  setSelectedQuestion: (question: SelectedQuestion | null) => void

  addSection: () => void
  removeSection: (sectionUid: Guid) => void
  setSectionTitle: (sectionUid: Guid, title: string) => void
  reorderSection: (sectionUid: Guid, direction: 'up' | 'down') => void
  canMoveUp: (sectionUid: Guid, questionUid: Guid) => boolean
  canMoveDown: (sectionUid: Guid, questionUid: Guid) => boolean

  addQuestion: (sectionUid: Guid, questionType: 'freetext' | 'yesNo') => void
  removeQuestion: (sectionUid: Guid, questionUid: Guid) => void
  reorderQuestion: (
    sectionUid: Guid,
    questionUid: Guid,
    direction: 'up' | 'down',
  ) => void

  setQuestionText: (sectionUid: Guid, questionUid: Guid, text: string) => void
  setQuestionDescription: (
    sectionUid: Guid,
    questionUid: Guid,
    description: string | null,
  ) => void
  setQuestionNext: (
    sectionUid: Guid,
    questionUid: Guid,
    target: ConditionTarget,
  ) => void
  setYesNoAnswerNext: (
    sectionUid: Guid,
    questionUid: Guid,
    answer: 'yes' | 'no',
    target: ConditionTarget,
  ) => void
  canPublish: () => boolean
}

export type EditorStoreState = QuestionnaireEditorState &
  QuestionnaireEditorActions & {
    selectedQuestion: SelectedQuestion | null
  }

export const createQuestionnaireEditorStoreInstance = () =>
  create<EditorStoreState>((set, get) => {
    const initialQuestionnaire = createDefaultQuestionnaire()
    return {
      questionnaire: initialQuestionnaire,
      validationErrors: calculateValidationErrors(initialQuestionnaire),
      resetQuestionnaire: () => {
        const newQuestionnaire = createDefaultQuestionnaire()
        set({
          questionnaire: newQuestionnaire,
          validationErrors: calculateValidationErrors(newQuestionnaire),
          selectedQuestion: null,
        })
      },
      loadQuestionnaire: (q) => {
        set({
          questionnaire: q,
          validationErrors: calculateValidationErrors(q),
          selectedQuestion:
            q.sections.length > 0
              ? {
                  sectionUid: q.sections[0].uid,
                  questionUid: q.sections[0].questions[0].uid,
                }
              : null,
        })
      },
      title: () => {
        return get().questionnaire.title
      },
      setTitle: (title) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            state.questionnaire.title = title
            state.questionnaire.updated = new Date()
            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )
          }),
        )
      },
      selectedQuestion: null,
      setSelectedQuestion: (question) => {
        set({ selectedQuestion: question })
      },
      addSection: () => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const section = createDefaultSection()
            state.questionnaire.sections.push(section)
            state.questionnaire.updated = new Date()
            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )
          }),
        )
      },
      removeSection: (sectionUid) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            state.questionnaire.sections = state.questionnaire.sections.filter(
              (s) => s.uid !== sectionUid,
            )
            state.questionnaire.updated = new Date()
            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )
          }),
        )
      },
      setSectionTitle: (sectionUid, title) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const section = state.questionnaire.sections.find(
              (s) => s.uid === sectionUid,
            )
            if (section) {
              section.title = title
              state.questionnaire.updated = new Date()
              state.validationErrors = calculateValidationErrors(
                state.questionnaire,
              )
            }
          }),
        )
      },
      reorderSection: (sectionUid, direction) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const { sections } = state.questionnaire
            const index = sections.findIndex((s) => s.uid === sectionUid)
            if (index !== -1) {
              const swapIndex = direction === 'up' ? index - 1 : index + 1
              if (swapIndex >= 0 && swapIndex < sections.length) {
                ;[sections[index], sections[swapIndex]] = [
                  sections[swapIndex],
                  sections[index],
                ]
                state.questionnaire.updated = new Date()
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      canMoveUp: (sectionUid, questionUid) => {
        const section = get().questionnaire.sections.find(
          (s) => s.uid === sectionUid,
        )
        if (!section) return false
        return section.questions.findIndex((q) => q.uid === questionUid) > 0
      },
      canMoveDown: (sectionUid, questionUid) => {
        const section = get().questionnaire.sections.find(
          (s) => s.uid === sectionUid,
        )
        if (!section) return false
        return (
          section.questions.findIndex((q) => q.uid === questionUid) <
          section.questions.length - 1
        )
      },
      addQuestion: (sectionUid, questionType) => {
        set((state) => {
          return produce(state as EditorStoreState, (draft) => {
            const section = draft.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              let question: Question
              if (questionType === 'freetext') {
                question = createDefaultFreeTextQuestion()
              } else {
                question = createDefaultYesNoQuestion()
              }
              section.questions.push(question)
              draft.selectedQuestion = { sectionUid, questionUid: question.uid }
              draft.validationErrors = calculateValidationErrors(
                draft.questionnaire,
              )
            }
          })
        })
      },
      removeQuestion: (sectionUid, questionUid) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              section.questions = section.questions.filter(
                (q) => q.uid !== questionUid,
              )
              state.questionnaire.updated = new Date()
              state.validationErrors = calculateValidationErrors(
                state.questionnaire,
              )
            }
          }),
        )
      },
      reorderQuestion: (sectionUid, questionUid, direction) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (!section) return
            const index = section.questions.findIndex(
              (q) => q.uid === questionUid,
            )
            if (index >= 0) {
              const newIndex = direction === 'up' ? index - 1 : index + 1
              if (newIndex >= 0 && newIndex < section.questions.length) {
                const temp = section.questions[index]
                section.questions[index] = section.questions[newIndex]
                section.questions[newIndex] = temp
                state.questionnaire.updated = new Date()
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      setQuestionText: (sectionUid, questionUid, text) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              const question = section.questions.find(
                (q) => q.uid === questionUid,
              )
              if (question) {
                question.question = text
                state.questionnaire.updated = new Date()
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      setQuestionDescription: (sectionUid, questionUid, description) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              const question = section.questions.find(
                (q) => q.uid === questionUid,
              )
              if (question) {
                question.description = description
                state.questionnaire.updated = new Date()
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      setQuestionNext: (sectionUid, questionUid, target) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              const question = section.questions.find(
                (q) => q.uid === questionUid,
              )
              if (question) {
                question.next = target
                state.questionnaire.updated = new Date()
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      setYesNoAnswerNext: (sectionUid, questionUid, answerValue, target) => {
        set(
          produce((state: QuestionnaireEditorState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              const question = section.questions.find(
                (q) => q.uid === questionUid,
              ) as YesNoQuestion | undefined
              if (question && question.type === 'yesNo') {
                const ans = question.answers.find(
                  (a) => a.answer === answerValue,
                )
                if (ans) {
                  ans.next = target
                  state.questionnaire.updated = new Date()
                  state.validationErrors = calculateValidationErrors(
                    state.questionnaire,
                  )
                }
              }
            }
          }),
        )
      },
      canPublish: () => {
        const { questionnaire, validationErrors } = get()
        if (
          validationErrors.missingTitle ||
          validationErrors.invalidSections.size > 0 ||
          validationErrors.invalidQuestions.size > 0
        ) {
          return false
        }

        if (
          questionnaire.sections.length === 0 ||
          questionnaire.sections.some((s) => s.questions.length === 0)
        ) {
          return false
        }

        return true
      },
    }
  })

export const useEditorStore = createQuestionnaireEditorStoreInstance()
