import analytics from '@capturi/analytics'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useDeleteSubscription } from 'pages/shared/Subscriptions/useSubscriptions'
import React from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
  subscriptionUid: string
}

const DeleteSubscriptionConfirmModal: React.FC<Props> = ({
  isOpen,
  onClose,
  subscriptionUid,
}) => {
  const { mutate: deleteSubscription, isPending } = useDeleteSubscription()
  const toast = useToast()
  const handleDeleteSubscription = (uid: string) => {
    deleteSubscription(uid, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: t`Successfully deleted subscription`,
        })
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t`Failed to deleted subscription. Please try again`,
          description: error.message,
        })
      },
    })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight="medium">
            <Trans>Are you sure you want to delete the subscription?</Trans>
          </Text>
          <Text>
            <Trans>
              This will remove the subscription completely, and the recipients
              will no longer receive e-mails related to this subscription.
            </Trans>
          </Text>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              handleDeleteSubscription(subscriptionUid)
              analytics.event('subscription_deleted', {
                subscriptionUid: subscriptionUid,
              })
            }}
            isDisabled={isPending}
            isLoading={isPending}
          >
            <Trans>Delete</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteSubscriptionConfirmModal
