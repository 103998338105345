import { Input, InputProps } from '@chakra-ui/react'
import { t } from '@lingui/macro'

export const Search: React.FC<InputProps> = (props) => {
  return (
    <Input
      {...props}
      autoFocus={true}
      padding="8px"
      marginBottom="8px"
      placeholder={t`Search...`}
    />
  )
}
