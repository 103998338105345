import { Flex, FormControl, FormLabel, Icon, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'
import { ArchiveChipsInput } from './ArchiveChipsInput'

const ExternalUidField: FC<{
  value: string[]
  onChange: (value: string[]) => void
  onClose: () => void
}> = ({ value, onChange, onClose }) => {
  return (
    <FormControl>
      <Flex justify="space-between" px={2}>
        <FormLabel mb={0}>
          <Trans>External user IDs</Trans>
        </FormLabel>
        <Icon as={MdClose} onClick={onClose} />
      </Flex>
      <Text color="gray.600" fontSize="xs" mb={1} px={2}>
        <Trans>
          The following external user IDs will be searched for in the archive
        </Trans>
      </Text>
      <ArchiveChipsInput
        title={t`Search customer IDs...`}
        values={value}
        onChange={onChange}
        onClose={onClose}
      />
    </FormControl>
  )
}

export default ExternalUidField
