import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { Flex, FlexProps } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { getRoutes as getAnalysisRoutes } from 'pages/Analysis/routes'
import askAIRoutes from 'pages/AskAI/routes'
import coachingRoutes from 'pages/Coaching/routes'
import commentRoutes from 'pages/CommentsPage/routes'
import conversationRoutes from 'pages/Conversations/routes'
import dashboardRoutes from 'pages/Dashboards/routes'
import { routes } from 'pages/KeyTopics/routes'
import libraryRoutes from 'pages/Library/routes'
import metabaseReportsRoutes from 'pages/MetabaseReports/routes'
import myPageRoutes from 'pages/MyPage/routes'
import { routes as organizationRoutes } from 'pages/Organization'
import profileRoutes from 'pages/ProfilePage/routes'
import { routes as qaQuestionnaireRoutes } from 'pages/QAQuestionnaire/routes'
import { routes as savedFiltersRoutes } from 'pages/SavedFiltersPage/routes'
import { routes as scoringRoutes } from 'pages/ScoringPage/routes'
import summariesRoutes from 'pages/Summaries/routes'
import trackersRoutes from 'pages/TrackersPage/routes'
import { trendsRootPath } from 'pages/TrendsV2'
import { getRoutes as getInsightsRoutes } from 'pages/analytics'
import React, { useMemo } from 'react'
import { FaGraduationCap } from 'react-icons/fa'
import {
  MdAssignment,
  MdDashboard,
  MdDataUsage,
  MdExplore,
  MdGraphicEq,
  MdHomeMini,
  MdOutlineStorm,
  MdPersonPinCircle,
  MdSettings,
  MdTimeline,
} from 'react-icons/md'

import { SidebarGroup } from '../components/SidebarGroup'
import SidebarLink from '../components/SidebarLink'
import NavigationGroup from './NavigationGroup'

const insightsRoutes = getInsightsRoutes()
const analysisRoutes = getAnalysisRoutes()

const MainMenu: React.FC<{ onClose: () => void } & FlexProps> = ({
  onClose,
}) => {
  const user = useCurrentUser()
  const {
    enableSentimentFeature,
    useEmailChannelAsDefault,
    aiFeatures,
    hideHomepage,
    isArchiveOrg,
    enableQAQuestionnaire,
  } = useFeatureFlags()

  const ConversationsRoutesArray = useMemo(
    () => [
      {
        name: <Trans>All</Trans>,
        key: 'conversations',
        url: useEmailChannelAsDefault
          ? conversationRoutes.cases
          : conversationRoutes.root,
        show: !isArchiveOrg,
        onClick: onClose,
      },
      {
        name: <Trans>AI Summaries</Trans>,
        key: 'summaries',
        url: summariesRoutes.root,
        show:
          (!useEmailChannelAsDefault && aiFeatures && !isArchiveOrg) ?? false,
        onClick: onClose,
      },
      {
        name: <Trans>Archive</Trans>,
        key: 'archive',
        url: '/archive',
        show: isArchiveOrg ?? false,
        onClick: onClose,
      },
    ],
    [onClose, aiFeatures, useEmailChannelAsDefault, isArchiveOrg],
  )

  const insightsRoutesArray = useMemo(
    () => [
      {
        name: <Trans>Trackers</Trans>,
        key: 'trackers',
        url: insightsRoutes.trackers.main(),
        show: !isArchiveOrg,
        onClick: onClose,
      },
      {
        name: <Trans>Trends</Trans>,
        key: 'trends',
        url: trendsRootPath,
        show: !isArchiveOrg && !useEmailChannelAsDefault,
        onClick: onClose,
      },
      {
        name: <Trans>Scoring</Trans>,
        key: 'scoring',
        url: scoringRoutes.root,
        show: !isArchiveOrg && !useEmailChannelAsDefault,
        onClick: onClose,
      },
      {
        name: <Trans>Sentiment</Trans>,
        key: 'sentiment',
        url: insightsRoutes.sentiment,
        show:
          !isArchiveOrg &&
          enableSentimentFeature &&
          !useEmailChannelAsDefault &&
          user.isAdminOrTeamLead,
        onClick: onClose,
      },
      {
        name: <Trans>Activity</Trans>,
        key: 'activity',
        url: insightsRoutes.activity,
        show:
          !isArchiveOrg && user.isAdminOrTeamLead && !useEmailChannelAsDefault,
        onClick: onClose,
      },
      {
        name: <Trans>Interaction</Trans>,
        key: 'interaction',
        url: insightsRoutes.interaction,
        show:
          !isArchiveOrg && user.isAdminOrTeamLead && !useEmailChannelAsDefault,
        onClick: onClose,
      },
    ],
    [
      enableSentimentFeature,
      onClose,
      useEmailChannelAsDefault,
      user.isAdminOrTeamLead,
      isArchiveOrg,
    ],
  )

  const analysisRoutesArray = useMemo(
    () => [
      {
        name: <Trans>Hit rate</Trans>,
        key: 'hitrate',
        url: analysisRoutes.hitrate,
        show: !isArchiveOrg && user.isAdminOrTeamLead,
        onClick: onClose,
      },
    ],
    [onClose, user.isAdminOrTeamLead, isArchiveOrg],
  )

  const coachingRoutesArray = useMemo(
    () => [
      {
        name: <Trans>Employees</Trans>,
        key: 'employees',
        url: coachingRoutes.root,
        show:
          !isArchiveOrg && user.isAdminOrTeamLead && !useEmailChannelAsDefault,
        onClick: onClose,
      },
      {
        name: <Trans>Library</Trans>,
        key: 'library',
        url: libraryRoutes.root,
        show: !isArchiveOrg && !useEmailChannelAsDefault,
        onClick: onClose,
      },
      {
        name: <Trans>Comments</Trans>,
        key: 'comments',
        url: commentRoutes.root,
        show: !isArchiveOrg && !useEmailChannelAsDefault,
        onClick: onClose,
      },
    ],
    [onClose, useEmailChannelAsDefault, user.isAdminOrTeamLead, isArchiveOrg],
  )

  const settingsRoutesArray = useMemo(
    () => [
      {
        name: <Trans>Organization</Trans>,
        key: 'organization',
        url: organizationRoutes.root,
        show: user.isAdmin && user.permissions.organization,
        onClick: onClose,
      },
      {
        name: <Trans>Segments</Trans>,
        key: 'segments',
        url: savedFiltersRoutes.root,
        show: !isArchiveOrg && user.permissions.editSegment,
        onClick: onClose,
      },
      {
        name: <Trans>QA templates</Trans>,
        key: 'qa',
        url: qaQuestionnaireRoutes.root,
        show: enableQAQuestionnaire,
        onClick: onClose,
      },
      {
        name: <Trans>Subscriptions</Trans>,
        key: 'subscriptions',
        url: '/settings/subscriptions',
        show: !isArchiveOrg && aiFeatures,
        onClick: onClose,
      },
      {
        name: <Trans>Edit trackers</Trans>,
        key: 'trackers',
        url: trackersRoutes.root,
        show:
          !isArchiveOrg &&
          user.isAdminOrTeamLead &&
          user.permissions.editTracker,
        onClick: onClose,
      },
      {
        name: <Trans>Profile</Trans>,
        key: 'profile',
        url: profileRoutes.root,
        show: true,
        onClick: onClose,
      },
    ],
    [
      onClose,
      user.isAdmin,
      user.isAdminOrTeamLead,
      user.permissions.editSegment,
      user.permissions.editTracker,
      user.permissions.organization,
      isArchiveOrg,
      aiFeatures,
      enableQAQuestionnaire,
    ],
  )

  return (
    <Flex direction="column" as="nav" gap="1">
      <SidebarGroup>
        <SidebarLink
          name={<Trans>My page</Trans>}
          to={myPageRoutes.root}
          icon={FaGraduationCap}
          show={!user.isAdminOrTeamLead}
          onClick={onClose}
        />
        <SidebarLink
          name={<Trans>Home</Trans>}
          to={'/home'}
          icon={MdHomeMini}
          show={
            aiFeatures &&
            user.isAdminOrTeamLead &&
            !useEmailChannelAsDefault &&
            !hideHomepage
          }
          onClick={onClose}
        />
        <SidebarLink
          name={<Trans>Ask AI</Trans>}
          to={askAIRoutes.root}
          icon={MdOutlineStorm}
          show={!isArchiveOrg && aiFeatures && !useEmailChannelAsDefault}
          onClick={onClose}
        />
        <NavigationGroup
          icon={MdGraphicEq}
          basePaths={['conversations']}
          groupName={<Trans>Conversations</Trans>}
          subRoutes={ConversationsRoutesArray}
        />
        <SidebarLink
          name={<Trans>Key topics</Trans>}
          to={routes.root}
          icon={MdDataUsage}
          show={!isArchiveOrg && aiFeatures}
          onClick={onClose}
        />
        <SidebarLink
          name={<Trans>Dashboards</Trans>}
          to={dashboardRoutes.root}
          icon={MdDashboard}
          show={!isArchiveOrg}
          onClick={onClose}
        />
        {user.permissions.viewReports && (
          <SidebarLink
            name={<Trans>Reports</Trans>}
            to={metabaseReportsRoutes.root}
            icon={MdAssignment}
            show={!isArchiveOrg}
            onClick={onClose}
          />
        )}
      </SidebarGroup>

      <NavigationGroup
        icon={MdExplore}
        basePaths={['insights']}
        groupName={<Trans>Insights</Trans>}
        subRoutes={insightsRoutesArray}
      />
      <NavigationGroup
        icon={MdTimeline}
        basePaths={['analysis']}
        groupName={<Trans>Analysis</Trans>}
        subRoutes={analysisRoutesArray}
        show={user.isAdminOrTeamLead}
      />
      <NavigationGroup
        icon={MdPersonPinCircle}
        basePaths={['coaching']}
        groupName={<Trans>Coaching</Trans>}
        subRoutes={coachingRoutesArray}
        show={!useEmailChannelAsDefault}
      />
      <NavigationGroup
        icon={MdSettings}
        basePaths={['settings']}
        groupName={<Trans>Settings</Trans>}
        subRoutes={settingsRoutesArray}
      />
    </Flex>
  )
}

export default React.memo(MainMenu)
