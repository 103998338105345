import { QaQuestionnaire } from './types'
export const fetchQuestionnaireList = (): Promise<QaQuestionnaire[]> => {
  return Promise.resolve([
    {
      uid: '550e8400-e29b-41d4-a716-446655440000',
      organizationUid: 'org-123',
      title: 'Customer Satisfaction Survey 2024',
      created: new Date('2024-01-15T09:00:00'),
      createdByUserUid: 'user-789',
      updated: new Date('2024-01-20T14:30:00'),
      updatedByUserUid: 'user-789',
      published: true,
      publishedAt: new Date('2024-01-20T15:00:00'),
      deleted: false,
      deletedByUserUid: null,
      deletedAt: null,
      sections: [
        {
          uid: 'section-001',
          title: 'General Feedback',
          questions: [
            {
              type: 'yesNo',
              uid: 'q-001',
              question: 'Was our service satisfactory?',
              description: 'Please consider your overall experience',
              next: { type: 'nextQuestion' },
              answers: [
                {
                  answer: 'yes',
                  mnemonic: 'y',
                  next: { type: 'nextQuestion' },
                },
                {
                  answer: 'no',
                  mnemonic: 'n',
                  next: { type: 'question', id: 'q-003' },
                },
              ],
            },
            {
              type: 'multi',
              uid: 'q-002',
              question: 'How did you hear about us?',
              description: null,
              next: { type: 'nextQuestion' },
              answers: [
                {
                  answer: 'Social Media',
                  mnemonic: 'a',
                  next: null,
                },
                {
                  answer: 'Friend/Family',
                  mnemonic: 'b',
                  next: null,
                },
                {
                  answer: 'Advertisement',
                  mnemonic: 'c',
                  next: null,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      uid: '550e8400-e29b-41d4-a716-446655440001',
      organizationUid: 'org-123',
      title: 'Employee Onboarding Checklist',
      created: new Date('2024-01-10T10:15:00'),
      createdByUserUid: 'user-456',
      updated: new Date('2024-01-18T16:45:00'),
      updatedByUserUid: 'user-456',
      published: true,
      publishedAt: new Date('2024-01-18T17:00:00'),
      deleted: false,
      deletedByUserUid: null,
      deletedAt: null,
      sections: [
        {
          uid: 'section-002',
          title: 'IT Setup',
          questions: [
            {
              type: 'yesNo',
              uid: 'q-003',
              question: 'Have you received your laptop?',
              description: null,
              next: { type: 'nextQuestion' },
              answers: [
                {
                  answer: 'yes',
                  mnemonic: 'y',
                  next: { type: 'nextQuestion' },
                },
                {
                  answer: 'no',
                  mnemonic: 'n',
                  next: { type: 'endSurvey' },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      uid: '550e8400-e29b-41d4-a716-446655440002',
      organizationUid: 'org-123',
      title: 'Product Feedback Survey (Draft)',
      created: new Date('2024-01-21T08:30:00'),
      createdByUserUid: 'user-789',
      updated: new Date('2024-01-21T08:30:00'),
      updatedByUserUid: 'user-789',
      published: false,
      publishedAt: null,
      deleted: false,
      deletedByUserUid: null,
      deletedAt: null,
      sections: [
        {
          uid: 'section-003',
          title: 'Product Usage',
          questions: [
            {
              type: 'freetext',
              uid: 'q-004',
              question: 'What features would you like to see added?',
              description: 'Please be specific in your suggestions',
              next: { type: 'nextQuestion' },
            },
          ],
        },
      ],
    },
    {
      uid: '550e8400-e29b-41d4-a716-446655440003',
      organizationUid: 'org-123',
      title: 'Website Usability Test (Draft)',
      created: new Date('2024-01-22T11:20:00'),
      createdByUserUid: 'user-123',
      updated: new Date('2024-01-22T11:20:00'),
      updatedByUserUid: 'user-123',
      published: false,
      publishedAt: null,
      deleted: false,
      deletedByUserUid: null,
      deletedAt: null,
      sections: [
        {
          uid: 'section-004',
          title: 'Navigation',
          questions: [
            {
              type: 'yesNo',
              uid: 'q-005',
              question: 'Was it easy to find what you were looking for?',
              description: null,
              next: { type: 'nextQuestion' },
              answers: [
                {
                  answer: 'yes',
                  mnemonic: 'y',
                  next: { type: 'nextQuestion' },
                },
                {
                  answer: 'no',
                  mnemonic: 'n',
                  next: { type: 'nextQuestion' },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      uid: '550e8400-e29b-41d4-a716-446655440004',
      organizationUid: 'org-123',
      title: 'Exit Interview Template (Draft)',
      created: new Date('2024-01-23T15:45:00'),
      createdByUserUid: 'user-456',
      updated: new Date('2024-01-23T15:45:00'),
      updatedByUserUid: 'user-456',
      published: false,
      publishedAt: null,
      deleted: false,
      deletedByUserUid: null,
      deletedAt: null,
      sections: [
        {
          uid: 'section-005',
          title: 'Feedback',
          questions: [
            {
              type: 'freetext',
              uid: 'q-006',
              question: 'What are your main reasons for leaving?',
              description: 'Your feedback helps us improve',
              next: { type: 'nextQuestion' },
            },
          ],
        },
      ],
    },
  ])
}
export const fetchQuestionnaireByUid = async (
  uid: string | undefined,
): Promise<QaQuestionnaire> => {
  if (!uid) {
    throw new Error('Questionnaire UID is required')
  }
  const list = await fetchQuestionnaireList()
  const questionnaire = list.find((q) => q.uid === uid)
  if (!questionnaire) {
    throw new Error('Questionnaire not found')
  }
  return questionnaire
}
