import analytics from '@capturi/analytics'
import {
  DEFAULT_TEXT_STATE,
  PhoneSegmentBuilderState,
  TextSegmentBuilderState,
  useSegmentStatesContext,
} from '@capturi/filters'
import { Button, PlaySnippetsButton } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Flex,
  Grid,
  Icon,
  IconButton,
  Skeleton,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useCallback, useMemo, useState } from 'react'
import { MdBolt, MdClose } from 'react-icons/md'
import { useMeasure } from 'react-use'

import { TextKeyTopicsDrawer } from 'components/KeyTopics/Drawer/TextKeyTopicsDrawer'
import { GraphBenchmarks } from '../../../components/KeyTopics/Benchmarks'
import { KeyTopicsDrawer } from '../../../components/KeyTopics/Drawer/KeyTopicsDrawer'
import { TopicsGraph, TopicsGraphSkeleton } from '../TopicsGraph'
import { TableMode, TopicsTable } from '../TopicsTable'
import {
  GraphTopicsNode,
  mapTopicsNodeToGraphData,
} from '../hooks/mapTopicsNodeToGraphData'
import { mapTopicsNodeToTableData } from '../hooks/mapTopicsNodeToTableData'
import { Benchmark, KeyTopicsReadyResponse, TopicsNode } from '../hooks/types'
import { useKeyTopicsSession } from '../hooks/useKeyTopicsSession'
import { KeyTopicsExamples } from './KeyTopicsExamples'
import { UnqualifiedAmount } from './UnqualifiedAmount'

export const KeyTopicsContentSkeleton: FC = () => {
  return (
    <>
      <Grid
        templateColumns="591px auto"
        minH="500px"
        w="100%"
        gap={16}
        id="key-topics-container-skeleton"
      >
        <Flex direction="column" gap={4}>
          <TopicsGraphSkeleton />
          <Grid templateColumns="repeat(3, 1fr)" gap={2}>
            <Skeleton
              borderRadius="md"
              minH={32}
              shadow="none"
              border="1px solid"
              borderColor="gray.200"
            />
            <Skeleton
              borderRadius="md"
              minH={32}
              shadow="none"
              border="1px solid"
              borderColor="gray.200"
            />
            <Skeleton
              borderRadius="md"
              minH={32}
              shadow="none"
              border="1px solid"
              borderColor="gray.200"
            />
          </Grid>
        </Flex>
        <Box overflowX="hidden" overflowY="auto">
          <Skeleton height="500px" />
        </Box>
      </Grid>
      <Divider w="100%" pt={6} />
      <Flex direction="column" mt={6} gap={2}>
        <Skeleton height="20px" w={48} />
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <Skeleton borderRadius="md" h={32} />
          <Skeleton borderRadius="md" h={32} />
          <Skeleton borderRadius="md" h={32} />
        </Grid>
      </Flex>
    </>
  )
}

export const KeyTopicsReadyContent: FC<{
  keyTopicsReadyResponse: Omit<KeyTopicsReadyResponse, 'status'>
}> = ({ keyTopicsReadyResponse }) => {
  const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null)

  const {
    localizedNodeEntityName,
    atRootLevel,
    atExploreSubtopic,
    colors,
    centerColor,
    currentTopicsData,
    selectNode,
    goBack,
    canGoBack,
    exploreSubtopics,
  } = useKeyTopicsSession(keyTopicsReadyResponse)

  const { states, updateState } = useSegmentStatesContext()

  const [tableMode, setTableMode] = useState<TableMode>('normal')
  const [selectedNodes, setSelectedNodes] = useState<
    Record<string, TopicsNode>
  >({})

  const isNodeSelected = useCallback(
    (node: TopicsNode) => {
      return !!selectedNodes[node.id]
    },
    [selectedNodes],
  )

  const channel = states.length > 0 ? states[0].channel : 'phone'

  const handleToggleSelectionMode = useCallback(() => {
    if (tableMode === 'selection') {
      const newKeyTopics = Object.values(selectedNodes).flatMap(
        (n) => n.topics?.map((t) => t.name) ?? [],
      )

      if (channel === 'phone') {
        const existingKeyTopics =
          states.length > 0 &&
          states[0].channel === 'phone' &&
          states[0].values.keyTopics
            ? states[0].values.keyTopics
            : []
        const uniqueSelectedTopics = Array.from(
          new Set([...existingKeyTopics, ...newKeyTopics]),
        )

        const state: PhoneSegmentBuilderState = {
          channel: 'phone',
          values: {
            ...(states.length > 0 && states[0].channel === 'phone'
              ? states[0].values
              : {}),
            keyTopics: uniqueSelectedTopics,
          },
        }

        updateState({ state, index: 0, keepId: false })
      } else if (channel === 'email') {
        const currentValues =
          states.length > 0 && states[0].channel === 'email'
            ? states[0].values
            : undefined

        const existingFilters = currentValues?.keyTopicFilters ?? []
        const updatedKeyTopicsFilters = [
          { values: newKeyTopics, inverted: false },
          ...existingFilters,
        ]

        const newTextState: TextSegmentBuilderState = {
          channel: 'email',
          values: {
            ...DEFAULT_TEXT_STATE,
            ...(currentValues ?? {}),
            keyTopicFilters: updatedKeyTopicsFilters,
          },
        }

        updateState({ state: newTextState, index: 0, keepId: false })
      }

      setSelectedNodes({})
    }

    setTableMode((prev) => (prev === 'normal' ? 'selection' : 'normal'))
  }, [tableMode, selectedNodes, channel, states, updateState])

  const handleCloseSelectionMode = useCallback(() => {
    setTableMode((prev) => (prev === 'normal' ? 'selection' : 'normal'))
  }, [])

  const [openSpeechKeyTopicsDrawer] = useModal(KeyTopicsDrawer)
  const [openTextTopicsDrawer] = useModal(TextKeyTopicsDrawer)
  const openKeyTopicsDrawer = useCallback(
    (
      node: {
        topicId: string
        subTopicId?: string
        label: string
        topics?: {
          name: string
          amount: number
        }[]
      },
      referenceBenchmark?: Benchmark,
    ) => {
      if (channel === 'email') {
        openTextTopicsDrawer({
          topicId: node.topicId,
          subTopicId: node.subTopicId,
          sessionUid: keyTopicsReadyResponse.uid,
          clusterName: node.label,
          topics: node.topics,
        })
      } else {
        openSpeechKeyTopicsDrawer({
          topicId: node.topicId,
          subTopicId: node.subTopicId,
          sessionUid: keyTopicsReadyResponse.uid,
          clusterName: node.label,
          topics: node.topics,
          referenceBenchmark:
            referenceBenchmark ?? keyTopicsReadyResponse.benchmark,
        })
      }
    },
    [
      channel,
      openTextTopicsDrawer,
      openSpeechKeyTopicsDrawer,
      keyTopicsReadyResponse,
    ],
  )

  const handleNodeSelection = useCallback(
    (node: TopicsNode) => {
      if (tableMode === 'selection') {
        if (selectedNodes[node.id]) {
          // already selected
          setSelectedNodes((prev) => {
            const newSelectedNodes = { ...prev }
            delete newSelectedNodes[node.id]
            return newSelectedNodes
          })
        } else {
          setSelectedNodes((prev) => {
            const newSelectedNodes = { ...prev }
            newSelectedNodes[node.id] = node
            return newSelectedNodes
          })
        }
        return
      }

      analytics.event('key_topics_open_cluster_table', {
        item: node,
        sessionUid: keyTopicsReadyResponse.uid,
      })

      selectNode(node.id)

      // we only open drawer when we are on sub key topic level (non-root)
      if (atRootLevel) {
        return
      }

      openKeyTopicsDrawer(node)
    },
    [
      selectedNodes,
      tableMode,
      selectNode,
      keyTopicsReadyResponse,
      openKeyTopicsDrawer,
      atRootLevel,
    ],
  )

  const handleGraphNodeSelection = useCallback(
    (node: GraphTopicsNode) => {
      selectNode(node.id)

      // we only open drawer when we are on sub key topic level (non-root)
      if (atRootLevel) {
        return
      }

      openKeyTopicsDrawer(node)
    },
    [selectNode, atRootLevel, openKeyTopicsDrawer],
  )

  const {
    data: graphData,
    graphNodeById,
    totalAmount,
  } = useMemo(
    () => mapTopicsNodeToGraphData(currentTopicsData),
    [currentTopicsData],
  )
  const { rows, otherRow } = useMemo(
    () => mapTopicsNodeToTableData(currentTopicsData),
    [currentTopicsData],
  )

  const [ref, { height }] = useMeasure<HTMLDivElement>()

  const rootTopic = useMemo(
    () =>
      keyTopicsReadyResponse.keyTopicClusters.find(
        (k) => k.id === currentTopicsData.topicId,
      ),
    [keyTopicsReadyResponse, currentTopicsData],
  )

  return (
    <>
      <Grid
        templateColumns="591px auto"
        minH="500px"
        w="100%"
        gap={16}
        id="key-topics-container"
      >
        <Flex
          direction="column"
          gap={4}
          ref={ref}
          opacity={tableMode === 'selection' ? 0.2 : 1}
          pointerEvents={tableMode === 'selection' ? 'none' : 'auto'}
          transition="opacity 0.2s"
        >
          <TopicsGraph
            graphData={graphData}
            colors={colors}
            centerColor={centerColor}
            totalAmount={totalAmount}
            graphNodeById={graphNodeById}
            hoveredNodeId={hoveredNodeId}
            setHoveredNodeId={setHoveredNodeId}
            selectNode={handleGraphNodeSelection}
            exploreSubtopics={exploreSubtopics}
            goBack={goBack}
            canGoBack={canGoBack}
            sessionUid={keyTopicsReadyResponse.uid}
          />
          {channel === 'phone' &&
            currentTopicsData.benchmark &&
            tableMode === 'normal' && (
              <Grid templateColumns="9fr 14fr 13fr" gap={2}>
                <GraphBenchmarks
                  benchmark={currentTopicsData.benchmark}
                  referenceBenchmark={keyTopicsReadyResponse.benchmark}
                  showReferenceBenchmark={!atRootLevel}
                />
              </Grid>
            )}
        </Flex>
        <Flex direction="column" h={height} gap={3}>
          {atRootLevel && rows && rows.length > 0 ? (
            <Flex justifyContent="end" gap="1">
              <Button
                leftIcon={<Icon as={MdBolt} boxSize="12px !important" />}
                onClick={handleToggleSelectionMode}
                primary={tableMode === 'selection'}
                isDisabled={
                  !(
                    tableMode !== 'selection' ||
                    Object.keys(selectedNodes).length > 0
                  )
                }
              >
                {tableMode === 'selection' ? (
                  <Trans>Apply selection to filter</Trans>
                ) : (
                  <Trans>Add key topic clusters to filter</Trans>
                )}
              </Button>
              {tableMode === 'selection' && (
                <IconButton
                  aria-label="Thumbs down"
                  fontSize="8px"
                  icon={<MdClose />}
                  size="sm"
                  variant="ghost"
                  onClick={handleCloseSelectionMode}
                />
              )}
            </Flex>
          ) : (
            rootTopic != null &&
            atExploreSubtopic === false && (
              <Flex justifyContent="end">
                <PlaySnippetsButton
                  onClick={(e) => {
                    e.stopPropagation()
                    const { label } = currentTopicsData
                    const { id, keyTopics, benchmark } = rootTopic

                    openKeyTopicsDrawer(
                      {
                        topicId: id,
                        subTopicId: undefined,
                        label,
                        topics: keyTopics,
                      },
                      benchmark,
                    )

                    analytics.event(
                      'key_topics_open_drawer_all_conversations',
                      {
                        sessionUid: keyTopicsReadyResponse.uid,
                        clusterName: label,
                      },
                    )
                  }}
                  label={t`Show details`}
                  withLabel
                />
              </Flex>
            )
          )}
          <TopicsTable
            rows={rows}
            colors={canGoBack ? [] : colors}
            sessionUid={keyTopicsReadyResponse.uid}
            referenceBenchmark={keyTopicsReadyResponse.benchmark}
            otherRow={otherRow}
            isNodeSelected={isNodeSelected}
            selectNode={handleNodeSelection}
            hoveredNodeId={hoveredNodeId}
            setHoveredNodeId={setHoveredNodeId}
            localizedNodeEntityName={localizedNodeEntityName}
            mode={tableMode}
            showTopicsSubtitle={!atRootLevel}
            openKeyTopicsDrawer={openKeyTopicsDrawer}
          />
          <UnqualifiedAmount
            amount={keyTopicsReadyResponse.amount}
            totalAmount={keyTopicsReadyResponse.totalAmount}
          />
        </Flex>
      </Grid>
      {tableMode === 'normal' && currentTopicsData.rootCauseExamples && (
        <>
          <Divider w="100%" pt={6} />
          <KeyTopicsExamples examples={currentTopicsData.rootCauseExamples} />
        </>
      )}
    </>
  )
}
