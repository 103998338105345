import { Box, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ReactNode } from 'react'

type FilterSelectOptionsProps = {
  selected: number
  total: number
  selectedItems: ReactNode
  unselectedItems: ReactNode
}

export const FilterSelectOptions: React.FC<FilterSelectOptionsProps> = ({
  selected,
  total,
  selectedItems,
  unselectedItems,
}) => {
  return (
    <div>
      <Box>
        {selected > 0 && (
          <>
            <Text fontSize="sm" color="gray.600" p={2}>
              <Trans>Selected ({selected})</Trans>
            </Text>
            {selectedItems}
          </>
        )}
        <Text fontSize="sm" color="gray.600" p={2}>
          <Trans>Options ({total})</Trans>
        </Text>
        {unselectedItems}
      </Box>
    </div>
  )
}
