import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FC, useCallback } from 'react'

import isEqual from 'react-fast-compare'
import { ConditionTarget } from '../types'
import {
  EditorStoreState,
  useEditorStore,
} from '../useQuestionnaireEditorStore'

interface QuestionEditorProps {
  sectionUid: string
  questionUid: string
}

export const QuestionEditor: FC = () => {
  const selectedQuestion = useEditorStore((state) => state.selectedQuestion)
  if (!selectedQuestion) {
    return (
      <Flex
        height="100%"
        alignItems="center"
        justifyContent="center"
        color="gray.500"
      >
        {t`Select a question to edit`}
      </Flex>
    )
  }
  return (
    <QuestionEditorForm
      sectionUid={selectedQuestion.sectionUid}
      questionUid={selectedQuestion.questionUid}
    />
  )
}

const QuestionEditorForm: FC<QuestionEditorProps> = ({
  sectionUid,
  questionUid,
}) => {
  const select = useCallback(
    (state: EditorStoreState) => ({
      question: state.questionnaire.sections
        .find((section) => section.uid === sectionUid)
        ?.questions.find((question) => question.uid === questionUid),
      canMoveUp: state.canMoveUp(sectionUid, questionUid),
      canMoveDown: state.canMoveDown(sectionUid, questionUid),
      setQuestionText: state.setQuestionText,
      setQuestionDescription: state.setQuestionDescription,
      setQuestionNext: state.setQuestionNext,
      setYesNoAnswerNext: state.setYesNoAnswerNext,
      reorderQuestion: state.reorderQuestion,
      removeQuestion: state.removeQuestion,
    }),
    [sectionUid, questionUid],
  )
  const {
    question,
    canMoveUp,
    canMoveDown,
    setQuestionText,
    setQuestionNext,
    setYesNoAnswerNext,
    reorderQuestion,
    removeQuestion,
    setQuestionDescription,
  } = useEditorStore(select, (a, b) => isEqual(a, b))

  if (!question) return null

  const handleNextChange = (target: ConditionTarget) => {
    setQuestionNext(sectionUid, questionUid, target)
  }

  const handleYesNoNextChange = (
    answer: 'yes' | 'no',
    target: ConditionTarget,
  ) => {
    setYesNoAnswerNext(sectionUid, questionUid, answer, target)
  }

  return (
    <Box p={6}>
      <Stack spacing={6}>
        {/* Header with controls */}
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="lg" fontWeight="medium">
            {question.type === 'yesNo'
              ? t`Yes/No Question`
              : t`Free Text Question`}
          </Text>
          <Flex gap={2}>
            <Button
              size="sm"
              onClick={() => reorderQuestion(sectionUid, question.uid, 'up')}
              isDisabled={!canMoveUp}
            >
              ↑
            </Button>
            <Button
              size="sm"
              onClick={() => reorderQuestion(sectionUid, question.uid, 'down')}
              isDisabled={!canMoveDown}
            >
              ↓
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              variant="outline"
              onClick={() => removeQuestion(sectionUid, question.uid)}
            >
              {t`Remove`}
            </Button>
          </Flex>
        </Flex>

        {/* Question Content */}
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>{t`Question Text`}</FormLabel>
            <Input
              autoFocus={true}
              value={question.question}
              onChange={(e) =>
                setQuestionText(sectionUid, question.uid, e.target.value)
              }
              placeholder={t`Enter question text`}
            />
          </FormControl>

          <FormControl>
            <FormLabel>{t`Description`}</FormLabel>
            <Textarea
              value={question.description || ''}
              onChange={(e) =>
                setQuestionDescription(
                  sectionUid,
                  question.uid,
                  e.target.value || null,
                )
              }
              placeholder={t`Enter optional description`}
              rows={3}
            />
          </FormControl>
        </Stack>

        {/* Conditional Logic */}
        <Box mt={8}>
          <Text fontSize="md" fontWeight="medium">
            {t`Conditions`}
          </Text>
          <Text fontSize="sm" color="gray.500" mb={4}>
            {t`Add conditions to jump between your questions`}
          </Text>
          <Stack spacing={4}>
            {question.type === 'yesNo' && (
              <>
                <FormControl>
                  <FormLabel>{t`If Yes, then:`}</FormLabel>
                  <Select
                    value={question.answers[0].next?.type}
                    onChange={(e) =>
                      handleYesNoNextChange('yes', {
                        type: e.target.value as 'nextQuestion' | 'endSurvey',
                      })
                    }
                  >
                    <option value="nextQuestion">{t`Continue to next question`}</option>
                    <option value="endSurvey">{t`End survey`}</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>{t`If No, then:`}</FormLabel>
                  <Select
                    value={question.answers[1].next?.type}
                    onChange={(e) =>
                      handleYesNoNextChange('no', {
                        type: e.target.value as 'nextQuestion' | 'endSurvey',
                      })
                    }
                  >
                    <option value="nextQuestion">{t`Continue to next question`}</option>
                    <option value="endSurvey">{t`End survey`}</option>
                  </Select>
                </FormControl>
              </>
            )}

            <FormControl>
              <FormLabel>
                {question.type === 'yesNo'
                  ? t`Otherwise:`
                  : t`After answering:`}
              </FormLabel>
              <Select
                value={question.next.type}
                onChange={(e) =>
                  handleNextChange({
                    type: e.target.value as 'nextQuestion' | 'endSurvey',
                  })
                }
              >
                <option value="nextQuestion">{t`Continue to next question`}</option>
                <option value="endSurvey">{t`End survey`}</option>
              </Select>
            </FormControl>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}
