import { Emoji, PageHeading } from '@capturi/ui-components'
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  Flex,
  HStack,
  Spinner,
  Tag,
  TagLabel,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { useTeams } from '@capturi/core'
import { FC } from 'react'
import { ArchivedConversation } from '../api/types'
import { usePreviewText } from '../api/useArchivedConversations'
import { AudioPlayer } from './AudioPlayer'
import { SettingsMenu } from './SettingsMenu'

type Props = {
  conversation: ArchivedConversation | undefined
  isLoading: boolean
}

const formatDate = (date: Date): string =>
  i18n.date(date, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

export const Conversation: FC<Props> = ({ conversation, isLoading }) => {
  //Only fetch preview immediately if type is 'Text'
  const { data: previewText, isLoading: isPreviewLoading } = usePreviewText(
    conversation?.uid,
    conversation?.type,
  )

  const { getTeamByUid } = useTeams()
  const teamName = getTeamByUid(conversation?.teamUid ?? '')?.name
  if (isLoading || isPreviewLoading) {
    return (
      <Flex mt={2} direction="column" alignItems="center" color="primary.500">
        <Spinner />
      </Flex>
    )
  }

  if (!conversation)
    return (
      <Flex mt={2} direction="column" alignItems="center">
        <Emoji symbol="👈" fontSize="70px" />
        <Text fontSize="lg">
          <Trans>No conversation selected</Trans>
        </Text>
        <Text fontSize="sm" color="textMuted" textAlign="center">
          <Trans>Select a conversation from the list on the left</Trans>
        </Text>
      </Flex>
    )
  return (
    <Flex direction="column" mb={4}>
      <HStack justify="space-between">
        <VStack align="flex-start">
          {conversation.queue && (
            <PageHeading>{conversation.queue}</PageHeading>
          )}
          {conversation.customer && (
            <Text color="gray.600">
              {conversation.customer} | {conversation.customerId}
            </Text>
          )}
        </VStack>

        <Flex gap={2}>
          <Flex flexDir="column" justify="center" align="flex-end">
            <HStack>
              {teamName && (
                <Tag
                  variant="outline"
                  color="gray.800"
                  border="1px solid"
                  borderColor="gray.800"
                >
                  <TagLabel>{teamName}</TagLabel>
                </Tag>
              )}
              {conversation.userName && <Text>{conversation.userName}</Text>}
            </HStack>
            <Text color="gray.600">
              {conversation?.date && formatDate(conversation.date)}
            </Text>
          </Flex>
          <Flex>
            <AvatarGroup size="md">
              <Avatar
                key={conversation.uid}
                size="sm"
                name={conversation.userName ?? ''}
              />
            </AvatarGroup>

            <SettingsMenu
              conversationUid={conversation.uid}
              type={conversation.type}
              hasFile={conversation.hasFile}
            />
          </Flex>
        </Flex>
      </HStack>
      <Flex flex="1">
        <HStack flexWrap="wrap" gap={2} mt={4}>
          <Tag
            backgroundColor="#fff"
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.1)"
            display="flex"
            alignItems="center"
            color="gray.400"
            lineHeight="1"
          >
            {conversation.uid}
          </Tag>
          {conversation.label && (
            <Tag
              variant="outline"
              color="gray.800"
              border="1px solid"
              borderColor="gray.800"
            >
              <Trans>Label:</Trans> {conversation.label}
            </Tag>
          )}
          {conversation.source && (
            <Tag
              variant="outline"
              color="gray.800"
              border="1px solid"
              borderColor="gray.800"
            >
              <Trans>Source:</Trans> {conversation.source}
            </Tag>
          )}
          {conversation.externalUserUid && (
            <Tag
              variant="outline"
              color="gray.800"
              border="1px solid"
              borderColor="gray.800"
            >
              <Trans>External user ID:</Trans> {conversation.externalUserUid}
            </Tag>
          )}
          <>
            {Object.entries(conversation?.metadata || {}).map(
              ([key, value]) => (
                <Tag key={key}>
                  <TagLabel>
                    <chakra.span fontWeight="bold">{key}:</chakra.span> {value}
                  </TagLabel>
                </Tag>
              ),
            )}
          </>
        </HStack>
      </Flex>
      {conversation.type === 'Speech' && conversation.hasFile && (
        <Flex mt={10} flexDir="column">
          <Card>
            <AudioPlayer conversationUid={conversation.uid} />
          </Card>
        </Flex>
      )}
      {conversation.type === 'Text' && !isLoading && (
        <Card p={4} mt={10}>
          {conversation.hasFile && previewText ? (
            <Flex flexDir="column">
              <Card p={4} mb={4} background="accents.lightBackground.default">
                <Box
                  whiteSpace="pre-wrap"
                  fontFamily="mono"
                  fontSize="sm"
                  overflowX="auto"
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '8px',
                      height: '8px',
                      borderRadius: '8px',
                      backgroundColor: 'gray.100',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'gray.300',
                      borderRadius: '8px',
                    },
                  }}
                >
                  <Text whiteSpace="pre-wrap">{previewText}</Text>
                </Box>
              </Card>
            </Flex>
          ) : (
            <Flex
              mt={10}
              flexDir="column"
              align="center"
              justify="center"
              h="100%"
            >
              <PageHeading>
                <Trans>Text example not available 🤐</Trans>
              </PageHeading>
              <Text color="gray.600" textAlign="center" mt={3}>
                <Trans>
                  The file may have been deleted, corrupt or inaccessible at the
                  moment.
                </Trans>
              </Text>
            </Flex>
          )}
        </Card>
      )}
    </Flex>
  )
}
