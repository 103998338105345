import {
  InaccessibleFilter,
  InaccessibleSavedTextFilter,
  PhoneFilterValues,
  SavedPhoneFilter,
  SavedTextFilter,
  TextFilterValues,
} from '@capturi/api-filters'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useCallbackRef } from '@chakra-ui/react'
import constate from 'constate'
import { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { toFilterValues, toTextFilterValues } from '../../mappers'
import { PhoneSegmentBuilderState, TextSegmentBuilderState } from '../../types'
import Counter from '../../utils/counter'
import { hasFilterValues } from '../../utils/filterState'

const StateCounter = Counter()

type PhoneFilterState = {
  /**
   * The filter values of the segment
   */
  values: PhoneFilterValues
  /**
   * A reference to a saved filter where to the `values` can be saved to
   */
  savedFilter?: SavedPhoneFilter | InaccessibleFilter
  /**
   * A sub filter state to applied on the segment produced by `values`
   */
  subFilterState?: PhoneFilterState
}

type TextFilterState = {
  /**
   * The filter values of the segment
   */
  values: TextFilterValues

  /**
   * A reference to a saved filter where to the `values` can be saved to
   */
  savedTextFilter?: SavedTextFilter | InaccessibleSavedTextFilter

  /**
   * A sub filter state to applied on the segment produced by `values`
   */
  subFilterState?: TextFilterState
}

export type PhoneSegmentState = {
  id: number
  channel: 'phone'
} & PhoneFilterState

export type TextSegmentState = {
  id: number
  channel: 'email'
} & TextFilterState

export type SegmentState = PhoneSegmentState | TextSegmentState

export const DEFAULT_STATE: PhoneFilterValues = {
  userUids: [],
  trackers: [],
  notTrackers: [],
  status: '',
  labels: [],
  subjects: [],
  duration: undefined,
  teamUids: [],
  customers: [],
  sentiment: null,
  speakers: [],
  externalIdentity: '',
  qaIsReviewed: null,
  scores: [],
  keyTopics: [],
  repeatCalls: [],
  customNumberProp1: undefined,
  customNumberProp2: undefined,
  customNumberProp3: undefined,
  customNumberProp4: undefined,
  customNumberProp5: undefined,
  customNumberProp6: undefined,
  customNumberProp7: undefined,
  customNumberProp8: undefined,
  customNumberProp9: undefined,
  customNumberProp10: undefined,
  customProp1: [],
  customProp2: [],
  customProp3: [],
  customProp4: [],
  customProp5: [],
  customProp6: [],
  customProp7: [],
  customProp8: [],
  customProp9: [],
  customProp10: [],
}

export const DEFAULT_TEXT_STATE: TextFilterValues = {
  directionFilters: [],
  durationFilters: [],
  emailsFilters: [],
  externalUidFilters: [],
  inboxFilters: [],
  senderFilters: [],
  statusFilters: [],
  tagFilters: [],
  teamFilters: [],
  userFilters: [],
  trackers: [],
  customFieldFilters: [],
  keyTopicFilters: [],
}

type Actions = {
  addState: () => void
  hasValues: (index: number) => boolean
  reinitWithSavedPhoneFilter: (savedFilter: SavedPhoneFilter) => void
  reinitWithSavedTextFilter: (savedFilter: SavedTextFilter) => void
  removeState: (index: number) => void
  updateState: (params: {
    state: PhoneSegmentBuilderState | TextSegmentBuilderState
    index: number
    keepId?: boolean
  }) => void
  updateSubFilterState: (
    state: PhoneSegmentBuilderState | TextSegmentBuilderState,
    index: number,
    keepId?: boolean,
  ) => void
  getIndexForState: (state: SegmentState) => number
}

type UseSegmentStatesResponse = {
  states: SegmentState[]
  phoneSegmentStates: PhoneSegmentState[]
  textSegmentStates: TextSegmentState[]
} & Actions

type InitialPhoneSegmentState = {
  savedFilter?: SavedPhoneFilter | InaccessibleFilter
  values?: Partial<PhoneFilterValues>
}

type InitialTextSegmentState = {
  savedFilter?: SavedTextFilter | InaccessibleSavedTextFilter
  values?: Partial<TextFilterValues>
}

const createDefaultPhoneState = (): SegmentState[] => [
  {
    id: StateCounter.next(),
    channel: 'phone',
    savedFilter: undefined,
    values: DEFAULT_STATE,
    subFilterState: undefined,
  },
]

const createIniitalTextState = (
  state?: InitialTextSegmentState,
  subfilterState?: InitialTextSegmentState,
): SegmentState[] => {
  let stateValues = state?.values
  if (
    stateValues == null &&
    state?.savedFilter &&
    'values' in state.savedFilter
  ) {
    // Accessible saved filter
    stateValues = toTextFilterValues(state.savedFilter.values)
  }

  let textSubFilterState: TextFilterState | undefined
  if (subfilterState) {
    textSubFilterState = {
      values: {
        ...DEFAULT_TEXT_STATE,
        ...subfilterState.values,
      },
      savedTextFilter: subfilterState.savedFilter,
    }
  }
  return [
    {
      id: StateCounter.next(),
      channel: 'email',
      subFilterState: textSubFilterState,
      values: {
        ...DEFAULT_TEXT_STATE,
        ...stateValues,
      },
      savedTextFilter: state?.savedFilter,
    },
  ]
}

const createIniitalPhoneState = (
  state?: InitialPhoneSegmentState,
  subfilterState?: InitialPhoneSegmentState,
): SegmentState[] => {
  let stateValues = state?.values
  if (
    stateValues == null &&
    state?.savedFilter &&
    'values' in state.savedFilter
  ) {
    // Accessible saved filter
    stateValues = toFilterValues(state.savedFilter.values ?? {})
  }

  let phoneSubFilterState: PhoneFilterState | undefined
  if (subfilterState) {
    phoneSubFilterState = {
      values: {
        ...DEFAULT_STATE,
        ...subfilterState.values,
      },
      savedFilter: subfilterState.savedFilter,
    }
  }

  return [
    {
      id: StateCounter.next(),
      channel: 'phone',
      savedFilter: state?.savedFilter ?? undefined,
      values: {
        ...DEFAULT_STATE,
        ...stateValues,
      },
      subFilterState: phoneSubFilterState,
    },
  ]
}

const createInitialState = (
  initialStates:
    | {
        state?: InitialPhoneSegmentState
        subfilterState?: InitialPhoneSegmentState
        channel: 'phone'
      }
    | {
        state?: InitialTextSegmentState
        subfilterState?: InitialTextSegmentState
        channel: 'email'
      }
    | undefined,
): SegmentState[] => {
  if (!initialStates) {
    return createDefaultPhoneState()
  }

  if (initialStates.channel === 'email') {
    return createIniitalTextState(
      initialStates.state,
      initialStates.subfilterState,
    )
  }
  return createIniitalPhoneState(
    initialStates.state,
    initialStates.subfilterState,
  )
}

export type SegmentStatesProviderProps = {
  initialState?:
    | {
        state?: InitialPhoneSegmentState
        subfilterState?: InitialPhoneSegmentState
        channel: 'phone'
      }
    | {
        state?: InitialTextSegmentState
        subfilterState?: InitialTextSegmentState
        channel: 'email'
      }
  onStatesChange?: (states: SegmentState[]) => void
}
export function useSegmentStates({
  initialState,
  onStatesChange: onStatesChangeProp,
}: SegmentStatesProviderProps): UseSegmentStatesResponse {
  const { useEmailChannelAsDefault } = useFeatureFlags()

  const [states, setStates] = useState<SegmentState[]>(() => {
    return createInitialState(initialState)
  })

  const onStatesChange = useCallbackRef(onStatesChangeProp)

  useUpdateEffect(() => {
    onStatesChange?.(states)
  }, [states, onStatesChange])

  const addState = useCallback((): void => {
    setStates((states) =>
      states.concat(
        useEmailChannelAsDefault
          ? {
              id: StateCounter.next(),
              channel: 'email',
              values: DEFAULT_TEXT_STATE,
            }
          : {
              id: StateCounter.next(),
              channel: 'phone',
              values: DEFAULT_STATE,
            },
      ),
    )
  }, [useEmailChannelAsDefault])

  const removeState = useCallback(
    (index: number): void => {
      setStates((states) => {
        if (states.length > 1) {
          return states.filter((_, i) => index !== i)
        }
        return createInitialState({
          channel: useEmailChannelAsDefault ? 'email' : 'phone',
        })
      })
    },
    [useEmailChannelAsDefault],
  )

  const updateState = useCallback(
    ({
      state,
      index,
      keepId = true,
    }: {
      state: PhoneSegmentBuilderState | TextSegmentBuilderState
      index: number
      keepId?: boolean
    }) => {
      setStates((states) =>
        states.map((s, i) => {
          if (i === index) {
            if (state.channel === 'phone') {
              return {
                ...s,
                id: keepId ? s.id : StateCounter.next(),
                values: { ...DEFAULT_STATE, ...state.values },
                savedFilter: state.savedFilter,
                subFilterState:
                  s.channel === 'phone' ? s.subFilterState : undefined,
                channel: state.channel,
              }
            }
            return {
              id: keepId ? s.id : StateCounter.next(),
              values: { ...DEFAULT_TEXT_STATE, ...state.values },
              savedTextFilter: state.savedTextFilter,
              channel: state.channel,
              subFilterState:
                s.channel === 'email' ? s.subFilterState : undefined,
            }
          }
          return s
        }),
      )
    },
    [],
  )

  const updateSubFilterState = useCallback(
    (
      state: PhoneSegmentBuilderState | TextSegmentBuilderState,
      index: number,
      keepId = true,
    ) => {
      setStates((states) => {
        return states.map((s, i) => {
          if (i === index) {
            if (state.channel === 'phone') {
              return {
                ...s,
                id: keepId ? s.id : StateCounter.next(),
                channel: state.channel,
                values: {
                  ...(s.channel === 'phone' ? s.values : DEFAULT_STATE),
                },
                subFilterState: {
                  channel: state.channel,
                  savedFilter: state.savedFilter,
                  values: { ...DEFAULT_STATE, ...state.values },
                },
              }
            }
            if (state.channel === 'email') {
              return {
                ...s,
                id: keepId ? s.id : StateCounter.next(),
                channel: state.channel,
                values: {
                  ...DEFAULT_TEXT_STATE,
                  ...(s.channel === 'email' ? s.values : undefined),
                },
                subFilterState: {
                  channel: state.channel,
                  savedTextFilter: state.savedTextFilter,
                  values: { ...DEFAULT_TEXT_STATE, ...state.values },
                },
              }
            }
          }
          return s
        })
      })
    },
    [],
  )

  const hasValues = useCallback(
    (stateIndex = 0) => {
      const state = states[stateIndex]
      if (!state) return false
      return hasFilterValues(state.values)
    },
    [states],
  )

  const getIndexForState = useCallback(
    (state: SegmentState | undefined): number => {
      return states.findIndex((s) => s.id === state?.id)
    },
    [states],
  )

  const reinitWithSavedPhoneFilter = useCallback(
    (savedFilter: SavedPhoneFilter): void => {
      setStates(
        createInitialState({
          channel: 'phone',
          state: {
            values: toFilterValues(savedFilter.values ?? {}),
            savedFilter,
          },
        }),
      )
    },
    [],
  )

  const reinitWithSavedTextFilter = useCallback(
    (savedFilter: SavedTextFilter): void => {
      setStates(
        createInitialState({
          channel: 'email',
          state: {
            values: toTextFilterValues(savedFilter.values),
            savedFilter,
          },
        }),
      )
    },
    [],
  )

  return useMemo(() => {
    return {
      states,
      phoneSegmentStates: states.filter(
        (s): s is PhoneSegmentState => s.channel === 'phone',
      ),
      textSegmentStates: states.filter(
        (s): s is TextSegmentState => s.channel === 'email',
      ),
      addState,
      hasValues,
      reinitWithSavedPhoneFilter,
      reinitWithSavedTextFilter,
      removeState,
      updateState,
      updateSubFilterState,
      getIndexForState,
    }
  }, [
    states,
    addState,
    hasValues,
    reinitWithSavedPhoneFilter,
    reinitWithSavedTextFilter,
    removeState,
    updateState,
    updateSubFilterState,
    getIndexForState,
  ])
}

const [SegmentStatesProvider, useSegmentStatesContext] =
  constate(useSegmentStates)

export { SegmentStatesProvider, useSegmentStatesContext }
