import { Box, useDisclosure, useOutsideClick } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC, useRef, useState } from 'react'
import {
  MdCallSplit,
  MdContactEmergency,
  MdContactMail,
  MdCreditCard,
  MdEmail,
  MdGroup,
  MdLabel,
  MdPerson,
  MdPhone,
  MdQueue,
} from 'react-icons/md'
import { match } from 'ts-pattern'
import { ArchivedConversationFilter } from '../api/types'
import { ActiveFilters } from './ActiveFilters'
import CustomerPhoneNumberField from './CustomerPhoneNumberField'
import CustomerIdField from './CustomerUidField'
import ExternalUidField from './ExternalUidField'
import { LabelSelect } from './Selects/LabelSelect'
import { QueueSelect } from './Selects/QueueSelect'
import { UserEmailSelect } from './Selects/UserEmailSelect'
import { UserNamesSelect } from './Selects/UserNamesSelect'

type Props = {
  filters: ArchivedConversationFilter
  onFiltersChange: (filters: ArchivedConversationFilter) => void
}

export const FilterWrapper: FC<Props> = ({ filters, onFiltersChange }) => {
  const [activeFilter, setActiveFilter] = useState<
    keyof typeof filterComponents | null
  >(null)
  const [filterPosition, setFilterPosition] = useState<{ left: number }>({
    left: 0,
  })
  const {
    isOpen,
    onOpen: onPopoverOpen,
    onClose: onFilterClose,
  } = useDisclosure()
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure()
  const filterRef = useRef<HTMLDivElement>(null)
  const addFilterRef = useRef<HTMLButtonElement>(null)

  const handleFilterUpdate = (
    type: keyof ArchivedConversationFilter,
    values: string[],
  ) => {
    onFiltersChange({
      ...filters,
      [type]: values,
    })
  }

  const handleRemoveFilter = (type: keyof ArchivedConversationFilter) => {
    const newFilters = { ...filters }
    delete newFilters[type]
    onFiltersChange(newFilters)
    if (type === activeFilter) {
      onFilterClose()
    }
  }

  const handleOutsideClick = (): void => {
    onMenuClose()
    onFilterClose()
  }

  useOutsideClick({
    ref: filterRef,
    handler: handleOutsideClick,
  })

  const filterComponents = {
    customers: {
      icon: <MdPhone />,
      label: <Trans>Customer phone/email</Trans>,
      component: (
        <CustomerPhoneNumberField
          value={filters.customers ?? []}
          onChange={(values) => handleFilterUpdate('customers', values)}
          onClose={onFilterClose}
        />
      ),
    },
    customerIds: {
      icon: <MdCreditCard />,
      label: <Trans>Customer ID</Trans>,
      component: (
        <CustomerIdField
          value={filters.customerIds ?? []}
          onChange={(values) => handleFilterUpdate('customerIds', values)}
          onClose={onFilterClose}
        />
      ),
    },
    externalUserUids: {
      icon: <MdContactEmergency />,
      label: <Trans>External user ID</Trans>,
      component: (
        <ExternalUidField
          value={filters.externalUserUids ?? []}
          onChange={(values) => handleFilterUpdate('externalUserUids', values)}
          onClose={onFilterClose}
        />
      ),
    },
    userNames: {
      icon: <MdPerson />,
      label: <Trans>Employee</Trans>,
      component: (
        <UserNamesSelect
          values={filters.userNames ?? []}
          onSubmit={(values) => handleFilterUpdate('userNames', values)}
          onClose={onFilterClose}
        />
      ),
    },
    userEmails: {
      icon: <MdContactMail />,
      label: <Trans>Employee email</Trans>,
      component: (
        <UserEmailSelect
          values={filters.userEmails ?? []}
          onSubmit={(values) => handleFilterUpdate('userEmails', values)}
          onClose={onFilterClose}
        />
      ),
    },
    labels: {
      icon: <MdLabel />,
      label: <Trans>Label</Trans>,
      component: (
        <LabelSelect
          values={filters.labels ?? []}
          onSubmit={(values) => handleFilterUpdate('labels', values)}
          onClose={onFilterClose}
        />
      ),
    },
    queues: {
      icon: <MdCallSplit />,
      label: <Trans>Queue/inbox</Trans>,
      component: (
        <QueueSelect
          values={filters.queues ?? []}
          onSubmit={(values) => handleFilterUpdate('queues', values)}
          onClose={onFilterClose}
        />
      ),
    },
  } as const

  const activeFilters = Object.entries(filters).reduce<
    Array<{
      type: keyof typeof filterComponents
      values: string[]
    }>
  >((acc, [key, values]) => {
    if (key !== 'fromInclusive' && key !== 'toInclusive' && key !== 'type') {
      acc.push({
        type: key as keyof typeof filterComponents,
        values: values as string[],
      })
    }
    return acc
  }, [])

  const handleFilterSelect = (type: keyof ArchivedConversationFilter) => {
    setActiveFilter(type as keyof typeof filterComponents)

    // Find if there's an existing filter button of this type. Maybe there's a smarter way with refs?
    const existingFilterButton = filterRef.current?.querySelector(
      `[data-filter-type="${type}"]`,
    ) as HTMLElement

    if (existingFilterButton) {
      // If filter exists, position under it
      const rect = existingFilterButton.getBoundingClientRect()
      const parentRect = filterRef.current?.getBoundingClientRect()
      if (parentRect) {
        setFilterPosition({ left: rect.left - parentRect.left })
      }
    } else {
      // New filter should just appear under the add filter button
      const addFilterRect = addFilterRef.current?.getBoundingClientRect()
      const parentRect = filterRef.current?.getBoundingClientRect()
      if (addFilterRect && parentRect) {
        setFilterPosition({ left: addFilterRect.left - parentRect.left })
      }
    }

    onPopoverOpen()
    onMenuClose()
  }

  const getIcon = (type: keyof typeof filterComponents) => {
    return match(type)
      .with('userNames', () => MdPerson)
      .with('userEmails', () => MdEmail)
      .with('customers', () => MdGroup)
      .with('customerIds', () => MdCreditCard)
      .with('externalUserUids', () => MdContactEmergency)
      .with('queues', () => MdQueue)
      .with('labels', () => MdLabel)
      .otherwise(() => MdLabel)
  }

  const getFilterLabel = (type: keyof typeof filterComponents) => {
    return match(type)
      .with('userNames', () => <Trans>Username</Trans>)
      .with('userEmails', () => <Trans>User email</Trans>)
      .with('customers', () => <Trans>Customer</Trans>)
      .with('customerIds', () => <Trans>Customer ID</Trans>)
      .with('externalUserUids', () => <Trans>External user ID</Trans>)
      .with('queues', () => <Trans>Queue/Inbox</Trans>)
      .with('labels', () => <Trans>Label</Trans>)
      .otherwise((type) => type)
  }

  return (
    <Box position="relative" ref={filterRef}>
      <Box width="100%">
        <ActiveFilters
          filters={activeFilters}
          onRemoveFilter={handleRemoveFilter}
          onOpen={handleFilterSelect}
          onClose={onFilterClose}
          isOpen={isOpen}
          getIcon={getIcon}
          getFilterLabel={getFilterLabel}
          isMenuOpen={isMenuOpen}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          addFilterRef={addFilterRef}
          filterComponents={filterComponents}
        />
      </Box>
      {isOpen && activeFilter && (
        <Box
          position="absolute"
          top="100%"
          left={`${filterPosition.left}px`}
          mt={2}
          width="400px"
          bg="white"
          boxShadow="lg"
          borderRadius="md"
          p={0}
          py={2}
          zIndex={1000}
        >
          {(() => {
            if (!activeFilter) return null
            return filterComponents[activeFilter].component
          })()}
        </Box>
      )}
    </Box>
  )
}
