import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FC, ReactNode, memo, useCallback, useEffect } from 'react'
import { MdCheckCircle, MdClose, MdTextFields } from 'react-icons/md'
import { MdAdd } from 'react-icons/md'

import isEqual from 'react-fast-compare'
import { Guid, Question } from '../types'
import {
  EditorStoreState,
  useEditorStore,
} from '../useQuestionnaireEditorStore'
import { QuestionEditor } from './QuestionEditor'
import { QuestionnairePreview } from './QuestionnairePreview'

const selectQuestion = (state: EditorStoreState) => ({
  setSelectedQuestion: state.setSelectedQuestion,
  invalidQuestions: state.validationErrors.invalidQuestions,
})
export const QuestionnaireQuestionItem: FC<{
  icon: ReactNode
  title: string
  sectionUid: Guid
  questionUid: Guid
  isSelected: boolean
}> = memo(({ icon, title, sectionUid, questionUid, isSelected }) => {
  const { setSelectedQuestion, invalidQuestions } = useEditorStore(
    selectQuestion,
    (a, b) => isEqual(a, b),
  )
  const hasQuestionError = invalidQuestions.has(questionUid)

  return (
    <Box
      key={questionUid}
      p={2}
      cursor="pointer"
      borderRadius="md"
      bg="gray.50"
      borderColor={
        hasQuestionError ? 'red.500' : isSelected ? 'primary.500' : 'gray.200'
      }
      borderWidth={1}
      _hover={{
        bg: isSelected ? 'gray.100' : 'gray.50',
      }}
      onClick={() =>
        setSelectedQuestion({
          sectionUid,
          questionUid,
        })
      }
      mr={2}
    >
      <Flex alignItems="center" gap={2}>
        {icon}
        <Text fontSize="sm" noOfLines={1}>
          {title || t`Question title`}
        </Text>
      </Flex>
    </Box>
  )
})

export const QuestionnaireSection: FC<{
  sectionUid: Guid
  sectionIndex: number
}> = memo(({ sectionUid, sectionIndex }) => {
  const select = useCallback(
    (state: EditorStoreState) => ({
      selectedQuestion: state.selectedQuestion,
      addQuestion: state.addQuestion,
      setSectionTitle: state.setSectionTitle,
      removeSection: state.removeSection,
      section: state.questionnaire.sections.find((s) => s.uid === sectionUid),
      invalidSections: state.validationErrors.invalidSections,
    }),
    [sectionUid],
  )
  const {
    section,
    invalidSections,
    selectedQuestion,
    addQuestion,
    setSectionTitle,
    removeSection,
  } = useEditorStore(select, (a, b) => isEqual(a, b))
  const handleRemoveSection = useCallback(() => {
    removeSection(sectionUid)
  }, [removeSection, sectionUid])

  const hasSectionError = invalidSections.has(sectionUid)
  const addYesNoQuestion = useCallback(() => {
    addQuestion(sectionUid, 'yesNo')
  }, [addQuestion, sectionUid])
  const addFreeTextQuestion = useCallback(() => {
    addQuestion(sectionUid, 'freetext')
  }, [addQuestion, sectionUid])
  if (!section) return null
  return (
    <VStack
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      w="100%"
      align="stretch"
      gap={2}
      pl={3}
      pt={3}
      pr={2}
      pb={4}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <FormControl isRequired isInvalid={hasSectionError}>
          <HStack h={12}>
            <Text fontSize="lg" fontWeight="medium">
              {sectionIndex + 1}.
            </Text>
            <Tooltip
              hasArrow
              label={
                hasSectionError ? t`Section title cannot be empty` : undefined
              }
            >
              <Input
                size="sm"
                h="100%"
                value={section.title}
                onChange={(e) => setSectionTitle(section.uid, e.target.value)}
                placeholder={t`Add section name...`}
                borderRadius="md"
                borderColor={hasSectionError ? 'red.500' : undefined}
              />
            </Tooltip>
            <Box h="100%">
              <IconButton
                aria-label={t`Remove section`}
                icon={<Icon as={MdClose} />}
                size="sm"
                variant="ghost"
                w="32px"
                h="32px"
                onClick={handleRemoveSection}
              />
            </Box>
          </HStack>
        </FormControl>
      </Flex>
      <Divider />
      <VStack align="stretch" pl={5} spacing={2}>
        {section.questions.map((question: Question) => (
          <QuestionnaireQuestionItem
            key={question.uid}
            icon={
              <Icon
                as={question.type === 'yesNo' ? MdCheckCircle : MdTextFields}
              />
            }
            title={question.question}
            sectionUid={section.uid}
            questionUid={question.uid}
            isSelected={selectedQuestion?.questionUid === question.uid}
          />
        ))}
        <Flex gap={1} justifyContent="center">
          <Menu isLazy>
            <MenuButton as={IconButton} icon={<Icon as={MdAdd} />} size="xs" />
            <MenuList>
              <MenuItem
                onClick={addFreeTextQuestion}
                icon={<Icon as={MdTextFields} />}
              >
                {t`Free text`}
              </MenuItem>
              <MenuItem
                onClick={addYesNoQuestion}
                icon={<Icon as={MdCheckCircle} />}
              >
                {t`Yes/no question`}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </VStack>
    </VStack>
  )
})

const listSelect = (state: EditorStoreState) =>
  state.questionnaire.sections.map((s) => s.uid)
export const QuestionnaireList: FC = () => {
  const sectionUids = useEditorStore(listSelect, (a, b) => isEqual(a, b))
  return (
    <VStack align="stretch" spacing={4} px={2}>
      {sectionUids.map((uid, index) => (
        <QuestionnaireSection key={uid} sectionUid={uid} sectionIndex={index} />
      ))}
    </VStack>
  )
}

const select = (state: EditorStoreState) => ({
  title: state.questionnaire.title,
  setTitle: state.setTitle,
  addSection: state.addSection,
  resetQuestionnaire: state.resetQuestionnaire,
  missingTitle: state.validationErrors.missingTitle,
})

export const QuestionnaireEditor: FC = () => {
  const { title, setTitle, addSection, resetQuestionnaire, missingTitle } =
    useEditorStore(select, (a, b) => isEqual(a, b))

  useEffect(() => {
    resetQuestionnaire()
  }, [resetQuestionnaire])

  return (
    <Box>
      <FormControl isInvalid={missingTitle} isRequired mb={4}>
        <FormLabel>{t`Questionnaire title`}</FormLabel>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={t`Enter questionnaire title`}
        />
      </FormControl>
      <Grid templateColumns="1fr 1fr 1fr" gap={0} h="calc(100vh - 200px)">
        {/* Left Column - Navigation */}
        <GridItem borderRight="1px" borderColor="gray.200" overflowY="auto">
          <QuestionnaireList />
          <Flex justifyContent="center" mt={4}>
            <IconButton
              aria-label={t`Add Section`}
              icon={<Icon as={MdAdd} />}
              size="sm"
              onClick={addSection}
              mt={4}
            />
          </Flex>
        </GridItem>

        {/* Middle Column - Question Editor */}
        <GridItem overflowY="auto">
          <QuestionEditor />
        </GridItem>

        {/* Right Column - Preview */}
        <GridItem background="gray.100" borderColor="gray.200" overflowY="auto">
          <QuestionnairePreview />
        </GridItem>
      </Grid>
    </Box>
  )
}
