import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, memo } from 'react'
import isEqual from 'react-fast-compare'
import {
  MdArrowBack,
  MdCheckCircleOutline,
  MdClose,
  MdHighlightOff,
} from 'react-icons/md'
import {
  EditorStoreState,
  useEditorStore,
} from '../useQuestionnaireEditorStore'

const select = (state: EditorStoreState) => {
  const flatQuestions = state.questionnaire.sections.flatMap((section, idx) =>
    section.questions.map((question) => ({
      section,
      question,
      sectionIndex: idx,
    })),
  )

  const selectedQuestion = state.selectedQuestion
  if (!selectedQuestion) {
    return {
      previousQuestion: null,
      question: null,
      sectionIndex: 0,
      progressPercent: 0,
    }
  }

  const currentIndex = flatQuestions.findIndex(
    (entry) =>
      entry.section.uid === selectedQuestion.sectionUid &&
      entry.question.uid === selectedQuestion.questionUid,
  )
  return {
    questionnaireTitle: state.questionnaire.title,
    setSelectedQuestion: state.setSelectedQuestion,
    previousQuestion: currentIndex > 0 ? flatQuestions[currentIndex - 1] : null,
    question: flatQuestions[currentIndex],
    sectionIndex: flatQuestions[currentIndex].sectionIndex,
    progressPercent: Math.round(
      ((currentIndex + 1) / flatQuestions.length) * 100,
    ),
  }
}

export const QuestionnairePreview: FC = memo(() => {
  const {
    questionnaireTitle,
    setSelectedQuestion,
    previousQuestion,
    question,
    progressPercent,
  } = useEditorStore(select, (a, b) => isEqual(a, b))

  // If no questions available, show empty state.
  if (!question) {
    return (
      <Box p={24} textAlign="center">
        <Text>No questions available</Text>
      </Box>
    )
  }

  // Handler for Previous button: move to previous question if exists.
  const handlePrevious = () => {
    if (previousQuestion) {
      setSelectedQuestion({
        sectionUid: previousQuestion.section.uid,
        questionUid: previousQuestion.question.uid,
      })
    }
  }

  return (
    <Box px={6} py={3}>
      <Box
        p={4}
        m={4}
        border="1px"
        borderColor="gray.200"
        borderRadius="8px"
        shadow="md"
      >
        {/* Header */}
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading size="md">{questionnaireTitle}</Heading>
          <IconButton
            aria-label={t`Close preview`}
            icon={<MdClose />}
            variant="ghost"
            onClick={() => {}}
          />
        </Flex>
        {/* Section Heading */}
        <Flex alignItems="center" direction="column" mb={4}>
          <Text fontSize="sm" fontWeight="medium" gap={2}>
            {question.sectionIndex + 1}. {question.section.title}
          </Text>

          {/* Question Text */}
          <Text fontSize="lg" fontWeight="semibold" maxW="100%">
            {question.question.question}
          </Text>

          {question.question.description && (
            <Text fontSize="sm" color="gray.500" maxW="100%">
              {question.question.description}
            </Text>
          )}
        </Flex>
        {/* Answering Interface */}
        {question.question.type === 'freetext' && (
          <Input
            placeholder={t`write your answer...`}
            mb={4}
            borderRadius="md"
            border="1px"
            borderColor="gray.200"
          />
        )}
        {question.question.type === 'yesNo' && (
          <Flex gap={4} mb={4}>
            <Flex
              flex="1"
              p={4}
              border="1px"
              borderColor="gray.200"
              borderRadius="md"
              alignItems="center"
              textAlign="center"
              cursor="pointer"
              direction="column"
            >
              <Icon as={MdCheckCircleOutline} boxSize={12} color="positive" />
              <HStack alignItems="baseline" justifyContent="center">
                <Text mt={2} fontWeight="bold">
                  <Trans>Yes</Trans>
                </Text>
                <Badge
                  borderRadius="md"
                  background="gray.50"
                  borderColor="gray.200"
                  borderWidth="1px"
                  color="gray.700"
                  shadow="kbd-inner"
                >
                  Y
                </Badge>
              </HStack>
            </Flex>
            <Flex
              flex="1"
              p={4}
              border="1px"
              borderColor="gray.200"
              borderRadius="md"
              alignItems="center"
              textAlign="center"
              cursor="pointer"
              direction="column"
            >
              <Icon as={MdHighlightOff} boxSize={12} color="negative" />
              <HStack alignItems="baseline" justifyContent="center">
                <Text mt={2} fontWeight="bold">
                  <Trans>No</Trans>
                </Text>
                <Badge
                  borderRadius="md"
                  background="gray.50"
                  borderColor="gray.200"
                  borderWidth="1px"
                  color="gray.700"
                  shadow="kbd-inner"
                >
                  N
                </Badge>
              </HStack>
            </Flex>
          </Flex>
        )}
        {/* Navigation Controls */}
        <Flex justifyContent="center" w="100%">
          <Button
            leftIcon={<MdArrowBack />}
            size="sm"
            onClick={handlePrevious}
            disabled={!previousQuestion}
          >
            <Trans>Previous</Trans>
          </Button>
        </Flex>

        {/* Progress Bar */}
        <Box
          position="relative"
          mt={12}
          h="4px"
          bg="gray.100"
          borderRadius="full"
        >
          <Box
            position="absolute"
            left={0}
            top={0}
            h="100%"
            w={`${progressPercent}%`}
            bg="signal.success"
            borderRadius="full"
            transition="width 0.3s ease-in-out"
          >
            <Box
              position="absolute"
              right="-4px"
              top="-32px"
              bg="signal.success"
              color="white"
              px={2}
              py={1}
              borderRadius="md"
              fontSize="sm"
              _after={{
                content: '""',
                position: 'absolute',
                bottom: '-4px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderLeft: '4px solid transparent',
                borderRight: '4px solid transparent',
                borderTop: '4px solid',
                borderTopColor: 'signal.success',
              }}
            >
              {progressPercent}%
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
