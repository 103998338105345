import { Footer } from './FilterSelectFooter'
import { OptionItem } from './FilterSelectOptionItem'
import { FilterSelectOptions } from './FilterSelectOptions'
import { Search } from './FilterSelectSearch'
export const FilterSelect = {
  Search,
  FilterSelectOptions,
  OptionItem,
  Footer,
}
