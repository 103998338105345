import {
  FilterPeriodSelectContainer,
  useFilterPeriodContext,
} from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { Emoji, PageHeading } from '@capturi/ui-components'
import { Box, Flex, Grid, GridItem, HStack, Icon, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useQueryClient } from '@tanstack/react-query'
import { FC, useEffect, useState } from 'react'
import { MdMail, MdPhone } from 'react-icons/md'
import { useSearchParams } from 'react-router'
import { ArchivedConversation, ArchivedConversationFilter } from './api/types'
import {
  createFilterQuery,
  useArchivedConversationList,
  useConversation,
  useConversationCount,
} from './api/useArchivedConversations'
import { ButtonGroup } from './components/ButtonGroup'
import { Conversation } from './components/Conversation'
import { ConversationList } from './components/ConversationList'
import { FilterWrapper } from './components/FilterWrapper'

// List of all possible filter types (fetched from backend. Chips filters are based on user input)
const filterTypes = ['userNames', 'userEmails', 'queues', 'labels'] as const

export const ArchivePage: FC = () => {
  usePageTitle(t`Archived Conversations`)
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedUid = searchParams.get('conversationUid')

  const setSelectedUid = (uid: string | null) => {
    setSearchParams((params) => {
      if (uid) {
        params.set('conversationUid', uid)
      } else {
        params.delete('conversationUid')
      }
      return params
    })
  }

  const { data: selectedConversation, isLoading: isConversationLoading } =
    useConversation(selectedUid ?? undefined)
  const { period } = useFilterPeriodContext()

  const [activeTypes, setActiveTypes] = useState<
    ArchivedConversation['type'][]
  >(['Speech', 'Text'])

  // Create filters with type only when exactly one type is selected
  const [filters, setFilters] = useState<ArchivedConversationFilter>({
    fromInclusive: period.from,
    toInclusive: period.to,
    type: activeTypes.length === 1 ? activeTypes[0] : null,
  })

  const {
    data: conversations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useArchivedConversationList(filters, period)

  const { data: conversationCount } = useConversationCount(filters, period)

  const queryClient = useQueryClient()

  // Prefetch the filters
  useEffect(() => {
    filterTypes.forEach((filterName) => {
      queryClient.prefetchQuery(createFilterQuery(filterName))
    })
  }, [queryClient])

  const handleFiltersChange = (newFilters: ArchivedConversationFilter) => {
    setFilters(newFilters)
    setSelectedUid(null)
    setSearchParams((params) => {
      params.delete('conversationUid')
      return params
    })
  }

  const handleConversationSelect = (uid: string) => {
    setSelectedUid(uid)
    setSearchParams((params) => {
      params.set('conversationUid', uid)
      return params
    })
  }

  const handleTypeFilter = (types: ArchivedConversation['type'][]) => {
    setActiveTypes(types)
    setFilters((prev) => ({
      ...prev,
      type: types.length === 1 ? types[0] : null,
    }))
  }

  return (
    <Flex
      className="conversations-container"
      flexDir="column"
      h="100vh"
      overflow="hidden"
    >
      <Box mb={4} flex="0 0 auto">
        <Flex align="flex-start" justify="space-between" mb={8}>
          <PageHeading h={8} lineHeight="shorter">
            <Trans>All conversations</Trans>
          </PageHeading>
          <HStack>
            <Text fontSize="sm" color="textMuted" textAlign="center">
              <Trans>Conversations:</Trans> {conversationCount?.count}
            </Text>
            <FilterPeriodSelectContainer
              onSelectPeriod={() =>
                handleFiltersChange({
                  ...filters,
                  fromInclusive: period.from,
                  toInclusive: period.to,
                })
              }
            />
          </HStack>
        </Flex>
        <HStack align="flex-start">
          <ButtonGroup
            leftButtonProps={{
              icon: <Icon as={MdPhone} />,
              'aria-label': 'audio-channel',
              onClick: () =>
                handleTypeFilter(
                  activeTypes.includes('Speech')
                    ? activeTypes.filter((t) => t !== 'Speech')
                    : [...activeTypes, 'Speech'],
                ),
            }}
            rightButtonProps={{
              icon: <Icon as={MdMail} />,
              'aria-label': 'text-channel',
              onClick: () =>
                handleTypeFilter(
                  activeTypes.includes('Text')
                    ? activeTypes.filter((t) => t !== 'Text')
                    : [...activeTypes, 'Text'],
                ),
            }}
            defaultActive={['left', 'right']}
          />
          <FilterWrapper
            filters={filters}
            onFiltersChange={handleFiltersChange}
          />
        </HStack>
      </Box>
      {conversations?.pages[0].length === 0 ? (
        <Flex mt={2} direction="column" alignItems="center">
          <Emoji symbol="👀" fontSize="70px" />
          <Text fontSize="lg">
            <Trans>No results</Trans>
          </Text>
          <Text fontSize="sm" color="textMuted" textAlign="center">
            <Trans>
              Try to increase the period up in the right corner or make your
              search terms broader
            </Trans>
          </Text>
        </Flex>
      ) : (
        <Grid
          templateColumns={{
            base: 'minmax(12.5rem, 19rem) 1px 1fr',
          }}
          gap={2}
          flex="1"
          h="100%"
          minH={0}
          overflow="hidden"
        >
          <GridItem position="relative" overflow="auto">
            <ConversationList
              conversations={conversations?.pages.flat()}
              selectedConversationUid={selectedUid}
              onConversationSelect={handleConversationSelect}
              canLoadMore={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
            />
          </GridItem>
          <GridItem bg="gray.200" w="1px" />
          <GridItem overflow="auto" p={2}>
            <Conversation
              conversation={selectedConversation}
              isLoading={isConversationLoading}
            />
          </GridItem>
        </Grid>
      )}
    </Flex>
  )
}
