import {
  DirectionPosition,
  DirectionValue,
  DurationComparator,
  DurationType,
  PhoneFilterValues,
  SenderPosition,
  SenderType,
  TextFilterValues,
} from '@capturi/api-filters'
import * as yup from 'yup'

const customNumberPropSchema = yup.object({
  min: yup.number(),
  max: yup.number(),
})

const customPropSchema = yup.array().of(yup.string().required()).optional()

const phoneFilterValuesSchema: yup.SchemaOf<Partial<PhoneFilterValues>> =
  yup.object({
    userUids: yup.array().of(yup.string().required()).optional(),
    trackers: yup
      .array()
      .of(
        yup.object({
          uids: yup.array().of(yup.string().required()),
          speakerId: yup.number().optional(),
          word: yup.string().optional(),
          inverted: yup.boolean().optional(),
        }),
      )
      .optional(),
    notTrackers: yup
      .array()
      .of(
        yup.object({
          uids: yup.array().of(yup.string().required()),
        }),
      )
      .optional(),
    labels: yup.array().of(yup.string().required()).optional(),
    subjects: yup.array().of(yup.string().required()).optional(),
    status: yup.string().optional(),
    duration: yup
      .object({
        min: yup.number(),
        max: yup.number(),
      })
      .optional(),
    teamUids: yup.array().of(yup.string().required()).optional(),
    customers: yup.array().of(yup.string()).optional(),
    sentiment: yup
      .object()
      .shape({
        score: yup.string(),
        speaker: yup.string(),
      })
      .optional(),
    speakers: yup.array().of(yup.number()).optional(),
    externalIdentity: yup.string().optional(),
    repeatCalls: yup.array().of(yup.object().required()).optional(),
    scores: yup
      .array()
      .of(
        yup.object({
          uid: yup.string().required(),
          min: yup.number(),
          max: yup.number(),
        }),
      )
      .optional(),
    keyTopics: yup.array().of(yup.string().required()).optional(),
    qaIsReviewed: yup.boolean().optional(),
    customNumberProp1: customNumberPropSchema,
    customNumberProp2: customNumberPropSchema,
    customNumberProp3: customNumberPropSchema,
    customNumberProp4: customNumberPropSchema,
    customNumberProp5: customNumberPropSchema,
    customNumberProp6: customNumberPropSchema,
    customNumberProp7: customNumberPropSchema,
    customNumberProp8: customNumberPropSchema,
    customNumberProp9: customNumberPropSchema,
    customNumberProp10: customNumberPropSchema,
    customProp1: customPropSchema,
    customProp2: customPropSchema,
    customProp3: customPropSchema,
    customProp4: customPropSchema,
    customProp5: customPropSchema,
    customProp6: customPropSchema,
    customProp7: customPropSchema,
    customProp8: customPropSchema,
    customProp9: customPropSchema,
    customProp10: customPropSchema,
  })

export function parsePhoneFilterValuesJSON(
  value: string | null | undefined,
): Partial<PhoneFilterValues> | undefined {
  if (value == null || !phoneFilterValuesSchema.isValidSync(value)) {
    return undefined
  }

  try {
    return JSON.parse(value)
  } catch {
    return undefined
  }
}

const multiValueFilterSchema = yup
  .array()
  .of(
    yup.object({
      values: yup.array().of(yup.string().required()).optional(),
      inverted: yup.boolean().defined(),
    }),
  )
  .notRequired()

const textFilterValuesSchema: yup.SchemaOf<Partial<TextFilterValues>> =
  yup.object({
    directionFilters: yup
      .array()
      .of(
        yup.object({
          position: yup
            .mixed<DirectionPosition>()
            .oneOf(Object.values(DirectionPosition))
            .required(),
          values: yup
            .array()
            .of(
              yup
                .mixed<DirectionValue>()
                .oneOf(Object.values(DirectionValue))
                .required(),
            )
            .optional(),
          inverted: yup.boolean().required(),
        }),
      )
      .notRequired(),
    durationFilters: yup
      .array()
      .of(
        yup.object({
          value: yup.number().required(),
          type: yup
            .mixed<DurationType>()
            .oneOf(Object.values(DurationType))
            .required(),

          comparator: yup
            .mixed<DurationComparator>()
            .oneOf(Object.values(DurationComparator))
            .required(),
        }),
      )
      .notRequired(),
    emailsFilters: multiValueFilterSchema,
    externalUidFilters: multiValueFilterSchema,
    inboxFilters: multiValueFilterSchema,
    keyTopicFilters: multiValueFilterSchema,
    senderFilters: yup
      .array()
      .of(
        yup.object({
          type: yup
            .mixed<SenderType>()
            .oneOf(Object.values(SenderType))
            .required(),
          position: yup
            .mixed<SenderPosition>()
            .oneOf(Object.values(SenderPosition))
            .required(),
          values: yup.array().of(yup.string().required()).optional(),
          inverted: yup.boolean().required(),
        }),
      )
      .notRequired(),
    statusFilters: multiValueFilterSchema,
    tagFilters: multiValueFilterSchema,
    teamFilters: multiValueFilterSchema,
    userFilters: multiValueFilterSchema,
    trackers: yup
      .array()
      .of(
        yup.object({
          uids: yup.array().of(yup.string().required()).optional(),
          phrase: yup.string().optional(),
          inverted: yup.boolean().required(),
        }),
      )
      .notRequired(),
    customFieldFilters: yup
      .array()
      .of(
        yup.object({
          field: yup.string().required(),
          values: yup.array().of(yup.string().required()).optional(),
          inverted: yup.boolean().required(),
        }),
      )
      .notRequired(),
  })

export function parseTextFilterValuesJSON(
  value: string | null | undefined,
): Partial<TextFilterValues> | undefined {
  if (value == null || !textFilterValuesSchema.isValidSync(value)) {
    return undefined
  }

  try {
    return JSON.parse(value)
  } catch {
    return undefined
  }
}
