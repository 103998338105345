import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, msg, t } from '@lingui/macro'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FC, memo, useCallback, useState } from 'react'
import {
  MdAdd,
  MdCopyAll,
  MdDelete,
  MdEdit,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md'
import { useNavigate } from 'react-router'

import { useCurrentUser } from '@capturi/core'
import { Caption, PageHeading } from '@capturi/ui-components'
import { fetchQuestionnaireList } from './api-stub'
import { routes } from './routes'
import { QaQuestionnaire } from './types'

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}

const questionTypeToMsg = {
  yesNo: msg`Yes/No`,
  multi: msg`Multi`,
  freetext: msg`Freetext answer`,
}

const QuestionnaireListItem: FC<{
  questionnaire: QaQuestionnaire
  onEdit?: (uid: string) => void
  onDelete: (uid: string) => void
  onClone: (uid: string) => void
}> = memo(({ questionnaire, onEdit, onDelete, onClone }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Box
      borderWidth={1}
      borderRadius="md"
      cursor="pointer"
      onClick={() => setIsExpanded(!isExpanded)}
      _hover={{ bg: 'gray.100' }}
    >
      <Flex p={4} alignItems="center" justifyContent="space-between">
        <Box flex={1}>
          <VStack align="flex-start" gap={2}>
            <Text fontSize="lg" fontWeight="medium">
              {questionnaire.title.length > 0
                ? questionnaire.title
                : t`Questionnaire title`}
            </Text>

            {questionnaire.publishedAt && (
              <Text fontSize="xs" color="gray.600">
                <Trans>
                  Published by {'<add publishedByUid to model>'} at{' '}
                  {i18n.date(questionnaire.publishedAt, dateOptions)}
                </Trans>
              </Text>
            )}
          </VStack>
        </Box>

        <Flex gap={4}>
          <HStack gap={1}>
            <Tooltip label={t`Delete`} hasArrow placement="top">
              <IconButton
                aria-label={t`Delete questionnaire`}
                icon={<Icon as={MdDelete} />}
                variant="ghost"
                size="xs"
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete(questionnaire.uid)
                }}
              />
            </Tooltip>
            <Tooltip label={t`Duplicate`} hasArrow placement="top">
              <IconButton
                aria-label={t`Clone questionnaire`}
                icon={<Icon as={MdCopyAll} />}
                variant="ghost"
                size="xs"
                onClick={(e) => {
                  e.stopPropagation()
                  onClone(questionnaire.uid)
                }}
              />
            </Tooltip>
            {onEdit && (
              <Tooltip label={t`Edit`} hasArrow placement="top">
                <IconButton
                  aria-label={t`Edit questionnaire`}
                  icon={<Icon as={MdEdit} />}
                  variant="ghost"
                  size="xs"
                  onClick={(e) => {
                    e.stopPropagation()
                    onEdit?.(questionnaire.uid)
                  }}
                />
              </Tooltip>
            )}
          </HStack>
          <IconButton
            aria-label={isExpanded ? t`Collapse` : t`Expand`}
            icon={
              <Icon as={isExpanded ? MdKeyboardArrowUp : MdKeyboardArrowDown} />
            }
            variant="ghost"
            size="xs"
            onClick={(e) => {
              e.stopPropagation()
              setIsExpanded(!isExpanded)
            }}
          />
        </Flex>
      </Flex>

      <Collapse in={isExpanded} unmountOnExit>
        <Box p={4} pt={0}>
          {questionnaire.sections.map((section, index) => (
            <Box key={section.uid} mt={4}>
              <Text fontWeight="medium" fontSize="sm" mb={1}>
                {index + 1}. {section.title}
              </Text>
              <Divider />
              <VStack align="stretch" spacing={2} mt={2}>
                {section.questions.map((question) => (
                  <Box key={question.uid}>
                    <Text color="gray.600" fontSize="sm">
                      {question.question}
                    </Text>
                    <Text fontWeight="medium">
                      {i18n._(questionTypeToMsg[question.type])}
                    </Text>
                  </Box>
                ))}
              </VStack>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  )
})

export const QAQuestionnaireListPage: FC = () => {
  const navigate = useNavigate()
  const { id: currentUserUid } = useCurrentUser()

  const handleCreateNew = () => {
    navigate(routes.create)
  }

  const { data: questionnaires, isLoading } = useQuery({
    queryKey: ['questionnaireList'],
    queryFn: fetchQuestionnaireList,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const handleEdit = useCallback(
    (uid: string) => {
      navigate(routes.edit(uid))
    },
    [navigate],
  )

  // TODO: replace with API mutation mutation
  const queryClient = useQueryClient()
  const handleDelete = useCallback(
    (uid: string) => {
      queryClient.removeQueries({ queryKey: ['questionnaire', uid] })
      queryClient.setQueryData(
        ['questionnaireList'],
        (old: QaQuestionnaire[]) => {
          return old.filter((q) => q.uid !== uid)
        },
      )
    },
    [queryClient],
  )

  const handleClone = useCallback(
    (uid: string) => {
      const original = questionnaires?.find((q) => q.uid === uid)
      if (!original) return

      const cloned: QaQuestionnaire = {
        ...original,
        uid: crypto.randomUUID(),
        title: `${original.title} (Copy)`,
        created: new Date(),
        createdByUserUid: currentUserUid,
        updated: new Date(),
        updatedByUserUid: currentUserUid,
        published: false,
        publishedAt: null,
      }

      queryClient.setQueryData(
        ['questionnaireList'],
        (old: QaQuestionnaire[]) => {
          return [...(old || []), cloned]
        },
      )
      queryClient.setQueryData(['questionnaire', cloned.uid], () => {
        return cloned
      })

      navigate(routes.edit(cloned.uid))
    },
    [navigate, queryClient, questionnaires, currentUserUid],
  )

  const { activeQuestionnaires = [], draftQuestionnaires = [] } =
    Object.groupBy(questionnaires ?? [], ({ published }) =>
      published ? 'activeQuestionnaires' : 'draftQuestionnaires',
    )

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
        <VStack align="flex-start" gap={0}>
          <PageHeading h={8} lineHeight="shorter">
            <Trans>QA templates</Trans>
          </PageHeading>
          <Caption color="textMuted">
            <Trans>
              Manage templates for manually quality assuring conversations
            </Trans>
          </Caption>
        </VStack>
        <Button
          colorScheme="primary"
          leftIcon={<Icon as={MdAdd} />}
          onClick={handleCreateNew}
        >
          {t`Create template`}
        </Button>
      </Flex>

      {!isLoading && questionnaires && questionnaires.length > 0 ? (
        <>
          <Text fontSize="lg" fontWeight="medium" mb={2}>
            {t`Active`}
          </Text>
          <VStack align="stretch" spacing={2}>
            {activeQuestionnaires.map((q) => (
              <QuestionnaireListItem
                key={q.uid}
                questionnaire={q}
                onDelete={handleDelete}
                onClone={handleClone}
              />
            ))}
          </VStack>

          <Box py={8}>
            <Divider />
          </Box>

          <Text fontSize="lg" fontWeight="medium" mb={2}>
            {t`Drafts`}
          </Text>
          <VStack align="stretch" spacing={2}>
            {draftQuestionnaires.map((q) => (
              <QuestionnaireListItem
                key={q.uid}
                questionnaire={q}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onClone={handleClone}
              />
            ))}
          </VStack>
        </>
      ) : (
        !isLoading && (
          <Stack spacing={4}>
            <Text>{t`No questionnaires available. Please create a new one.`}</Text>
          </Stack>
        )
      )}
    </Box>
  )
}
