import { PlayButton } from '@capturi/ui-components'
import {
  Box,
  Button,
  HStack,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FC, useCallback, useRef, useState } from 'react'
import { MdForward10, MdPause, MdPlayArrow, MdReplay10 } from 'react-icons/md'

const formatCurrentTime = (currentTime: number) => {
  const minutes = Math.floor(currentTime / 60)
  const seconds = Math.floor(currentTime % 60)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60)
  const seconds = Math.floor(duration % 60)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export const AudioPlayer: FC<{ conversationUid: string }> = ({
  conversationUid,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const progressRef = useRef<HTMLDivElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const [playbackRate, setPlaybackRate] = useState(1)
  const toast = useToast()

  const audioSrc =
    process.env.NODE_ENV === 'development'
      ? 'https://cdn.freesound.org/previews/717/717965_1648170-lq.mp3'
      : `https://api.capturi.ai/storage/archive/${conversationUid}/preview`

  const handlePlayPause = async () => {
    if (audioRef.current) {
      if (!isLoaded) {
        audioRef.current.src = audioSrc
        audioRef.current.load()
        setIsLoaded(true)
      }

      if (isPlaying) {
        audioRef.current.pause()
        setIsPlaying(false)
      } else {
        try {
          await audioRef.current.play()
          setIsPlaying(true)
        } catch {
          toast({
            title: t`Error playing audio file`,
            description: t`The audio file could not be played. Please try again later.`,
            status: 'error',
          })
          setIsLoaded(false)
        }
      }
    }
  }

  const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (audioRef.current && progressRef.current) {
      const rect = progressRef.current.getBoundingClientRect()
      const x = e.clientX - rect.left
      const percentage = (x / rect.width) * 100
      const time = (percentage / 100) * audioRef.current.duration
      audioRef.current.currentTime = time
      setProgress(time)
    }
  }

  const toggleSpeed = useCallback((): void => {
    setPlaybackRate((currentRate) => {
      if (currentRate < 2) {
        return currentRate + 0.25
      }
      if (audioRef.current) {
        audioRef.current.playbackRate = currentRate
      }
      return 1
    })
  }, [])

  return (
    <Box width="100%">
      <audio
        ref={audioRef}
        onTimeUpdate={(e) => setProgress(e.currentTarget.currentTime)}
        onLoadedMetadata={() => formatDuration(progress)}
        onEnded={() => setIsPlaying(false)}
        hidden
      />

      <Box
        border="1px solid"
        borderColor="gray.200"
        p={4}
        bg="accents.lightBackground.default"
        borderTopRadius="md"
      >
        <Box px={4} py={2}>
          <Box
            ref={progressRef}
            h="24px"
            bg="gray.300"
            cursor="pointer"
            onClick={handleProgressClick}
            position="relative"
          >
            <Box
              position="absolute"
              left={0}
              top={0}
              h="full"
              bg="gray.400"
              w={`${(progress / (audioRef.current?.duration || 1)) * 100}%`}
              transition="width 0.1s"
            />
            <Box
              position="absolute"
              top="50%"
              transform="translate(-50%, -50%)"
              left={`${(progress / (audioRef.current?.duration || 1)) * 100}%`}
              w="5px"
              h="100%"
              bg="white"
              border="1px solid"
              borderColor="gray.400"
              transition="left 0.1s"
              zIndex={1}
            />
          </Box>
        </Box>
      </Box>

      <Box
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={4}
      >
        <HStack spacing={4} align="start">
          <HStack spacing={4}>
            <PlayButton
              aria-label={isPlaying ? 'Pause' : 'Play'}
              icon={isPlaying ? <MdPause /> : <MdPlayArrow />}
              onClick={handlePlayPause}
              size="sm"
              colorScheme="primary"
              isRound
            />

            <Text fontSize="sm" color="gray.600">
              {formatCurrentTime(progress)} /{' '}
              {formatDuration(audioRef?.current?.duration || 0)}
            </Text>
          </HStack>
          <IconButton
            aria-label="Skip backward"
            icon={<MdReplay10 />}
            size="sm"
            variant="ghost"
            onClick={() => {
              if (audioRef.current) {
                audioRef.current.currentTime -= 10
              }
            }}
          />

          <IconButton
            aria-label="Skip forward"
            icon={<MdForward10 />}
            size="sm"
            variant="ghost"
            onClick={() => {
              if (audioRef.current) {
                audioRef.current.currentTime += 10
              }
            }}
          />

          <Button
            aria-label="Change playback speed"
            onClick={toggleSpeed}
            size="sm"
            variant="ghost"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          >
            {playbackRate}x
          </Button>
        </HStack>
      </Box>
    </Box>
  )
}
