import { Message } from '@capturi/api-cases/src/models'
import { ShapeMark } from '@capturi/sharing'
import { useUsers } from '@capturi/stores'
import { useTheme } from '@capturi/ui-theme'
import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { css, keyframes } from '@emotion/react'
import { i18n } from '@lingui/core'
import UserAvatar from 'components/UserAvatar'
import React, { useMemo } from 'react'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'

import { Hit, createTextNodes, removeOverlap } from './highlightWordsUtils'
import { TrackerShape } from './types'

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const glow = keyframes`
  50% {
    box-shadow:  -0.307px -3.226px 31.039px 0px rgba(47, 150, 208, 0.28), -0.183px -1.917px 16.881px 0px rgba(47, 150, 208, 0.22), -0.095px -0.995px 8.613px 0px rgba(47, 150, 208, 0.17), -0.039px -0.406px 4.319px 0px rgba(47, 150, 208, 0.15), -0.009px -0.092px 2.086px 0px rgba(47, 150, 208, 0.9);
  }
`

const MessageBubble: React.FC<{
  message: Message
  selectedTrackerUid: string | null
  trackersMap: Map<string, TrackerShape>
  scrollToMessageRef: React.RefObject<HTMLDivElement> | null
}> = ({ message: m, selectedTrackerUid, trackersMap, scrollToMessageRef }) => {
  const { getUserByUid } = useUsers()
  const theme = useTheme()

  //Filters out trackers the users doens't have access to
  const trackerHitsOnMessage = (m.trackerHits || []).reduce<TrackerShape[]>(
    (memo, hit) => {
      const tracker = trackersMap.get(hit.trackerUid)
      if (tracker) {
        memo.push(tracker)
      }
      return memo
    },
    [],
  )

  const mergedHits = useMemo(
    () =>
      (m.trackerHits || [])
        .reduce<Hit[]>((memo, h) => {
          const trackerUid = h.trackerUid
          if (selectedTrackerUid !== h.trackerUid) return memo

          const words = Object.values(h.phrases).flatMap((phrase) => {
            return phrase.reduce<Hit[]>((memo, p) => {
              const shape = trackersMap.get(trackerUid)
              if (shape)
                memo.push({
                  from: p.from,
                  to: p.to,
                  trackerUid: trackerUid,
                  color: shape.color,
                  shape: shape.shape,
                  field: p.field,
                })

              return memo
            }, [])
          }, [])
          // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
          return [...memo, ...words]
        }, [])
        .sort((a, b) => a.to - b.to),
    [m.trackerHits, selectedTrackerUid, trackersMap],
  )

  const hasSelectedTrackers = mergedHits.length > 0
  // TODO: consider splitting up bodyHits and subjectHits inside the mergedHits thing
  // fixing th  overlap inside that as well would be nice
  const bodyHits = useMemo(
    () => removeOverlap(mergedHits.filter((h) => h.field === 'Text') || []),
    [mergedHits],
  )
  const subjectHits = useMemo(
    () => removeOverlap(mergedHits.filter((h) => h.field === 'Subject') || []),
    [mergedHits],
  )

  const bodyNodes = useMemo(
    () => createTextNodes(bodyHits, m.text),
    [bodyHits, m.text],
  )
  const subjectNodes = useMemo(
    () => createTextNodes(subjectHits, m.subject),
    [m.subject, subjectHits],
  )

  const { name, email, profileImage } = useMemo(
    () =>
      m.type === 'Outbound'
        ? getUserByUid(m.userUid)
        : { ...m.from, profileImage: null },
    [getUserByUid, m.from, m.type, m.userUid],
  )

  const handleGetColorValue = React.useCallback(
    (color: string) => {
      return getColor(theme, color)
    },
    [theme],
  )

  return (
    <Flex key={m.uid} ref={scrollToMessageRef} flexDirection="column" pt={2.5}>
      <Box
        backgroundColor="gray.100"
        mb={1.5}
        borderRadius="16px"
        id={m.uid}
        mr={m.type === 'Inbound' ? '16' : '0'}
        ml={m.type !== 'Inbound' ? '16' : '0'}
      >
        <Card
          bg={
            m.type === 'Inbound'
              ? theme.colors.cases.inbound
              : m.type === 'AutoReply'
                ? theme.colors.cases.autoReply
                : theme.colors.cases.outbound
          }
          border={hasSelectedTrackers ? '1px solid #2F96D0' : 'none'}
          css={
            hasSelectedTrackers &&
            css`
      animation: ${glow} 1s ease infinite; 
    `
          }
        >
          <Box
            display="flex"
            flexDirection={m.type === 'Inbound' ? 'row' : 'row-reverse'}
          >
            {trackerHitsOnMessage.map((th) => (
              <Box
                key={th.uid}
                mt="-6px"
                mx={th.uid === selectedTrackerUid ? '2px' : '-2px'}
                display="inline-block"
                h="0"
                transform={
                  th.uid === selectedTrackerUid ? 'scale(2)' : 'scale(1)'
                }
                transition="all .2s ease-in-out"
              >
                <ShapeMark
                  key={th.uid}
                  color={th.color}
                  shape={th.shape}
                  outline={false}
                  getColorValue={handleGetColorValue}
                />
              </Box>
            ))}
          </Box>
          <CardHeader p={4}>
            <HStack
              justifyContent="flex-end"
              align="top"
              flexDirection={m.type === 'Inbound' ? 'row-reverse' : 'row'}
            >
              <Box w="100%" textAlign={m.type === 'Inbound' ? 'left' : 'right'}>
                <HStack
                  justify="space-between"
                  flexDirection={m.type === 'Inbound' ? 'row-reverse' : 'row'}
                >
                  <HStack
                    flexDirection={m.type === 'Inbound' ? 'row-reverse' : 'row'}
                    gap="1"
                  >
                    <Text
                      color="gray.600"
                      fontSize="sm"
                      title={m.to.map(({ email }) => email).join(', ')}
                      noOfLines={1}
                      maxW="300px"
                    >
                      {m.to.length > 0 && (m.to[0].name || m.to[0].email)}
                      {m.to.length > 1 && `, +${m.to.length - 1}`}
                    </Text>
                    <Icon
                      borderRadius={4}
                      as={m.type === 'Inbound' ? MdArrowForward : MdArrowBack}
                      color="gray.600"
                      aria-label="message to icon"
                    />
                  </HStack>
                  {email !== name ? (
                    <Text
                      fontSize="md"
                      lineHeight="1.2"
                      fontWeight="medium"
                      noOfLines={1}
                      maxW="300px"
                    >
                      {name}
                    </Text>
                  ) : (
                    <Text
                      color="gray.800"
                      fontWeight="medium"
                      lineHeight="1"
                      noOfLines={1}
                      maxW="300px"
                    >
                      {email}
                    </Text>
                  )}
                </HStack>

                <HStack
                  justify="space-between"
                  align="baseline"
                  flexDirection={m.type === 'Inbound' ? 'row-reverse' : 'row'}
                  w="100%"
                >
                  <Text color="gray.600" fontSize="sm">
                    {m.type} | {i18n.date(m.created, dateFormat)}
                  </Text>
                  {email !== name && (
                    <Tooltip label={`${name} <${email}>`}>
                      <Text
                        color="gray.600"
                        fontWeight="regular"
                        lineHeight="1"
                      >
                        {email}
                      </Text>
                    </Tooltip>
                  )}
                </HStack>
              </Box>
              {m.type === 'Inbound' ? (
                <Avatar size="xs" />
              ) : (
                <UserAvatar size="xs" profileImage={profileImage} name={name} />
              )}
            </HStack>
          </CardHeader>

          <Divider m={4} w="calc ( 100 % - 32px )" borderColor="gray.400" />
          <CardBody p={4}>
            <VStack gap="4" align="stretch">
              {subjectNodes.length > 0 && (
                <Text
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontWeight="medium"
                >
                  {subjectNodes}
                </Text>
              )}
              <Text
                whiteSpace="break-spaces"
                wordBreak="break-word"
                color="gray.800"
              >
                {bodyNodes}
              </Text>
            </VStack>
          </CardBody>
        </Card>
      </Box>
    </Flex>
  )
}
export default MessageBubble
