import { Box, Button, Flex, Heading, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FC, useCallback, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import { useNavigate, useParams } from 'react-router'

import { fetchQuestionnaireByUid } from './api-stub'
import { QuestionnaireEditor } from './components/QuestionnaireEditor'
import { routes } from './routes'
import { QaQuestionnaire } from './types'
import { EditorStoreState, useEditorStore } from './useQuestionnaireEditorStore'

const select = (state: EditorStoreState) => ({
  canPublish: state.canPublish(),
  loadQuestionnaire: state.loadQuestionnaire,
})

export const QAQuestionnaireEditPage: FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { uid } = useParams<{ uid: string }>()

  const { canPublish, loadQuestionnaire } = useEditorStore(select, (a, b) =>
    isEqual(a, b),
  )

  const { data: fetchedQuestionnaire } = useQuery({
    queryKey: ['questionnaire', uid],
    queryFn: () => fetchQuestionnaireByUid(uid),
    enabled: !!uid,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (fetchedQuestionnaire) {
      loadQuestionnaire(fetchedQuestionnaire)
    }
  }, [fetchedQuestionnaire, loadQuestionnaire])

  const handleCancel = () => {
    navigate(routes.root)
  }

  // TODO: Replace with API mutations
  const queryClient = useQueryClient()

  const handleSave = useCallback(() => {
    const questionnaire = useEditorStore.getState().questionnaire
    try {
      queryClient.setQueryData(
        ['questionnaire', uid],
        (old: QaQuestionnaire) => {
          return {
            ...old,
            ...questionnaire,
          }
        },
      )

      queryClient.setQueryData(
        ['questionnaireList'],
        (old: QaQuestionnaire[]) =>
          old
            ? old.map((q) => (q.uid === uid ? { ...q, ...questionnaire } : q))
            : [questionnaire],
      )

      toast({
        title: t`Questionnaire saved`,
        status: 'success',
        duration: 3000,
      })
      navigate(routes.root)
    } catch (error) {
      toast({
        title: t`Error saving questionnaire`,
        description: error instanceof Error ? error.message : t`Unknown error`,
        status: 'error',
        duration: 5000,
      })
    }
  }, [navigate, queryClient, toast, uid])

  const handlePublish = () => {
    const questionnaire = useEditorStore.getState().questionnaire

    try {
      if (!canPublish) {
        toast({
          title: t`Cannot publish questionnaire`,
          description: t`Please fix all validation errors before publishing`,
          status: 'error',
          duration: 5000,
        })
        return
      }

      queryClient.setQueryData(
        ['questionnaire', uid],
        (old: QaQuestionnaire) => {
          return {
            ...old,
            ...questionnaire,
            published: true,
          }
        },
      )
      queryClient.setQueryData(
        ['questionnaireList'],
        (old: QaQuestionnaire[]) => {
          return old.map((q) =>
            q.uid === uid ? { ...q, ...questionnaire, published: true } : q,
          )
        },
      )
      toast({
        title: t`Questionnaire published`,
        status: 'success',
        duration: 3000,
      })
      navigate(routes.root)
    } catch (error) {
      toast({
        title: t`Error publishing questionnaire`,
        description: error instanceof Error ? error.message : t`Unknown error`,
        status: 'error',
        duration: 5000,
      })
    }
  }

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading as="h1" size="lg">
          {t`Edit QA Questionnaire`}
        </Heading>
        <Flex gap={2}>
          <Button variant="outline" onClick={handleCancel}>
            {t`Cancel`}
          </Button>
          <Button onClick={handleSave}>{t`Save as Draft`}</Button>
          <Button
            colorScheme="teal"
            onClick={handlePublish}
            isDisabled={!canPublish}
          >
            {t`Publish`}
          </Button>
        </Flex>
      </Flex>

      <QuestionnaireEditor />
    </Box>
  )
}
