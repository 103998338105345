import { Box, Divider, Spinner } from '@chakra-ui/react'
import { FilterSelect } from 'pages/Archive/FilterSelect/components/FilterSelect'
import { Footer } from 'pages/Archive/FilterSelect/components/FilterSelectFooter'
import { useFilterData } from 'pages/Archive/api/useArchivedConversations'
import { FC, useCallback, useMemo, useState } from 'react'

type Props = {
  values?: string[]
  onSubmit: (values: string[]) => void
  onClose: () => void
}

export const LabelSelect: FC<Props> = ({ values = [], onSubmit, onClose }) => {
  const { data } = useFilterData('labels')
  const [searchQuery, setSearchQuery] = useState('')

  const filteredOptions = useMemo(() => {
    if (!data?.values) return []
    if (!searchQuery) return data.values

    return data.values.filter((label) =>
      label.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [data?.values, searchQuery])

  const handleSelect = (label: string) => {
    if (values.includes(label)) {
      onSubmit(values.filter((s) => s !== label))
    } else {
      onSubmit([...values, label])
    }
  }

  const handleSelectAll = useCallback(() => {
    const newSelections =
      filteredOptions.filter((item) => !values.includes(item)) ?? []
    onSubmit([...new Set([...values, ...newSelections])])
    setSearchQuery('')
  }, [values, filteredOptions, onSubmit])

  const handleReset = useCallback(() => {
    onSubmit([])
  }, [onSubmit])

  const { selectedItems, unselectedItems } = useMemo(
    () =>
      filteredOptions.reduce<{
        selectedItems: string[]
        unselectedItems: string[]
      }>(
        (acc, label) => {
          if (values.includes(label)) {
            acc.selectedItems.push(label)
          } else {
            acc.unselectedItems.push(label)
          }
          return acc
        },
        { selectedItems: [], unselectedItems: [] },
      ),
    [filteredOptions, values],
  )

  if (!data) {
    return <Spinner />
  }

  return (
    <Box maxH="400px" display="flex" flexDirection="column">
      <Box px={2}>
        <FilterSelect.Search
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      <Divider />
      <Box flex={1} maxH="350px" overflowY="auto">
        <FilterSelect.FilterSelectOptions
          selected={values.length}
          total={filteredOptions.length}
          selectedItems={selectedItems.map((label) => (
            <FilterSelect.OptionItem
              key={label}
              isSelected={true}
              onClick={() => handleSelect(label)}
            >
              {label}
            </FilterSelect.OptionItem>
          ))}
          unselectedItems={unselectedItems.map((label) => (
            <FilterSelect.OptionItem
              key={label}
              isSelected={false}
              onClick={() => handleSelect(label)}
            >
              {label}
            </FilterSelect.OptionItem>
          ))}
        />
      </Box>
      <Box flexShrink={0}>
        <Footer
          selectedCount={values.length}
          onSelectAll={handleSelectAll}
          onReset={handleReset}
          onApply={onClose}
        />
      </Box>
    </Box>
  )
}
