import {
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import { ArchivedConversationFilter } from '../api/types'

type FilterKeys = keyof Omit<
  ArchivedConversationFilter,
  'fromInclusive' | 'toInclusive' | 'type'
>

type ActiveFilter = {
  type: FilterKeys
  values: string[]
}

type Props = {
  filters: ActiveFilter[]
  onRemoveFilter: (type: FilterKeys) => void
  onOpen: (filterType: FilterKeys) => void
  onClose: () => void
  isOpen: boolean
  getIcon: (type: FilterKeys) => React.ComponentType
  getFilterLabel: (type: FilterKeys) => React.ReactNode
  isMenuOpen: boolean
  onMenuOpen: () => void
  onMenuClose: () => void
  addFilterRef: React.RefObject<HTMLButtonElement>
  filterComponents: Record<
    string,
    { icon: JSX.Element; label: React.ReactNode }
  >
}

const MAX_ENTRIES_PER_FILTER = 4

const formatFilterValues = (values: string[]) => {
  if (values.length <= MAX_ENTRIES_PER_FILTER) {
    return values.join(', ')
  }
  const visibleValues = values.slice(0, MAX_ENTRIES_PER_FILTER)
  const remainingCount = values.length - MAX_ENTRIES_PER_FILTER
  return `${visibleValues.join(', ')} +${remainingCount}`
}

export const ActiveFilters: FC<Props> = ({
  filters,
  onRemoveFilter,
  onOpen,
  onClose,
  isOpen,
  getIcon,
  getFilterLabel,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  addFilterRef,
  filterComponents,
}) => {
  return (
    <Flex gap={2} flexWrap="wrap">
      {filters.map(
        (filter) =>
          filter.values?.length > 0 && (
            <Tooltip
              key={filter.type}
              label={`${filter.values.join(', ')}`}
              hasArrow
              placement="top"
              isDisabled={filter.values.length <= MAX_ENTRIES_PER_FILTER}
            >
              <Button
                data-filter-type={filter.type}
                borderBottom="2px solid"
                borderBottomColor="gray.300"
                background="gray.100"
                variant="unstyled"
                maxH="32px"
                minH="32px"
                height="auto"
                padding="2"
                onClick={(e) => {
                  e.stopPropagation()
                  if (isOpen) {
                    onClose()
                  } else {
                    onOpen(filter.type)
                  }
                }}
              >
                <HStack spacing={2}>
                  <Icon as={getIcon(filter.type)} />
                  <Text maxW="500px" overflow="hidden" textOverflow="ellipsis">
                    <strong>{getFilterLabel(filter.type)}</strong> is{' '}
                    {formatFilterValues(filter.values)}
                  </Text>
                  <Icon
                    as={MdClose}
                    onClick={(e) => {
                      e.stopPropagation()
                      onRemoveFilter(filter.type)
                    }}
                  />
                </HStack>
              </Button>
            </Tooltip>
          ),
      )}

      <Menu
        isOpen={isMenuOpen}
        onClose={onMenuClose}
        onOpen={() => {
          onMenuOpen()
          onClose()
        }}
        closeOnSelect={false}
      >
        <MenuButton
          ref={addFilterRef}
          as={Button}
          leftIcon={<MdAdd />}
          borderBottom="2px solid"
          borderBottomColor="gray.300"
          borderRadius="md"
          minH="32px"
          maxH="32px"
          height="auto"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Trans>Add filter</Trans>
        </MenuButton>
        <MenuList>
          {(
            Object.keys(filterComponents) as Array<
              keyof typeof filterComponents
            >
          ).map((type) => (
            <MenuItem
              key={type}
              icon={filterComponents[type].icon}
              onClick={() => onOpen(type as FilterKeys)}
            >
              {filterComponents[type].label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}
