import analytics from '@capturi/analytics'
import { useCurrentUser } from '@capturi/core/src/hooks/useCurrentUser'
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC } from 'react'
import {
  MdCopyAll,
  MdDownload,
  MdFileDownload,
  MdMoreVert,
} from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'
import { useDownloadArchivedConversation } from '../api/useArchivedConversations'

type Props = {
  conversationUid: string
  type: 'Text' | 'Speech'
  hasFile: boolean
}

export const SettingsMenu: FC<Props> = ({ conversationUid, type, hasFile }) => {
  const user = useCurrentUser()
  const [, copy] = useCopyToClipboard()
  const toast = useToast()
  const { mutate: handleDownload, isPending: isDownloading } =
    useDownloadArchivedConversation()

  const onDownload = () => {
    handleDownload(conversationUid, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: t`Downloaded conversation`,
        })
      },
      onError: () => {
        toast({
          status: 'error',
          title: t`Failed to download conversation`,
        })
      },
    })
  }

  const handleCopyConversationUid = (): void => {
    analytics.event('archivedConversation_copyConversationUid')
    copy(conversationUid)
    toast({
      status: 'info',
      title: t`Copied conversation ID to clipboard`,
      description: conversationUid,
    })
  }
  return (
    <Menu placement={'bottom-end'}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<MdMoreVert />}
        variant="ghost"
        size="sm"
      />

      <MenuList>
        <MenuItem onClick={handleCopyConversationUid}>
          <MdCopyAll />
          <Text ml="2">
            <Trans>Copy conversation ID to clipboard</Trans>
          </Text>
        </MenuItem>
        {user.permissions.download && user.permissions.playback && hasFile && (
          <>
            {type === 'Speech' && (
              <MenuItem
                icon={<MdDownload />}
                onClick={() => onDownload()}
                isDisabled={isDownloading}
              >
                <Trans>Download audio</Trans>
              </MenuItem>
            )}
            {type === 'Text' && (
              <MenuItem onClick={() => onDownload()}>
                <MdFileDownload />
                <Text ml="2">
                  <Trans>Download text</Trans>
                </Text>
              </MenuItem>
            )}
          </>
        )}
      </MenuList>
    </Menu>
  )
}
